import './loader.css';

import React from 'react';

export function Loader() {
    return (
        <div className="flex justify-center">
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export function FullScreenLoader(){
    return (
        <div className="fixed z-50 bg-opacity-25 flex flex-col justify-center items-center bg-black top-0 right-0 left-0 bottom-0">
            <div className="transform scale-150 ">
                <Loader/>
            </div>
        </div>
    )
}
