import React from 'react';

import {
  Link,
  useHistory,
} from 'react-router-dom';

import { LoginPopUp } from '../components/auth_popup';
import { Container } from '../components/container';
import { Loader } from '../components/loader';
import {
  useCartContext,
  useCustomerContext,
} from '../context/app_context';
import { parseToNaira } from '../utils/functions';
import { ProductPageBottom } from './product-details';

export default function ProductCart() {
    const cart = useCartContext();
    const customer = useCustomerContext();
    const history = useHistory();
    const cartProducts = cart.cartProducts;
    const cartTotal = cart.cartTotal;
    const checkOut = (navigate) => {
        if (navigate) {
            history.push("checkout");
        }
    };
    return (
        <Container>
            {cartProducts.loading && (
                <div className="py-10">
                    <Loader />
                </div>
            )}
            {!cart.store.items.length ? (
                <div className="py-20 text-center">No product in cart</div>
            ) : (
                <>
                    <div className="my-10 grid grid-cols-8 gap-8 items-center">
                        <div className="col-span-6"></div>
                        <div className="hidden md:block text-xl">Savings</div>
                        <div className="font-bold md:text-xl">SubTotal</div>
                    </div>
                    <div className="divide-y divide-gray-100">
                        {cart.store.items.map((cart_item) => {
                            const item = cartProducts.data.find(
                                (v) => v.id === cart_item.p_id
                            );
                            if (item) {
                                return <CartItem cartItem={item} />;
                            } else {
                                return <div></div>;
                            }
                        })}
                    </div>
                    <div className="flex text-lg md:text-xl font-extrabold p-5 md:p-12 border-t-2 border-b-2  ">
                        <div className="text-sm text-k4-gray font-normal">
                            <img
                                className="inline-block h-5 mr-1"
                                src={require("./../assets/apply_code.svg")}
                                alt={""}
                            />
                            Apply Code
                        </div>
                        <p className="mr-3 md:mr-0 md:px-16 ml-auto">
                            SubTotal
                        </p>
                        <p className="md:ml-12 t-4 md:mt-0">
                            {parseToNaira(cartTotal)}
                        </p>
                    </div>
                    <div className="flex md:justify-end px-5 md:px-12 py-5 md:py-10 text-xl font-black">
                        <p className="md:px-12">
                            Total <br />
                            <span className="text-xs font-normal">
                                Excluding delivery
                            </span>
                        </p>
                        <p className="ml-auto md:ml-12">
                            {parseToNaira(cartTotal)}
                        </p>
                    </div>
                    <div className="flex mb-3 md:mb-0 justify-center md:justify-end">
                        <Link
                            to="/products"
                            className="py-2 text-deep-red px-5 md:px-8 mr-4 border border-deep-red rounded-md text-sm"
                        >
                            Continue shopping
                        </Link>
                        {cart.store.items.length &&
                            (customer.store.customer_details ? (
                                <button
                                    onClick={checkOut}
                                    className="bg-deep-red rounded-md font-semibold border border-deep-red text-white px-8 md:px-16 py-2 text-xs md:text-sm"
                                >
                                    Checkout
                                </button>
                            ) : (
                                <LoginPopUp>
                                    {({ onClick }) => (
                                        <button
                                            onClick={() => {
                                                onClick();
                                                checkOut(false);
                                            }}
                                            className="bg-deep-red rounded-md font-semibold border border-deep-red text-white px-8 md:px-16 py-2 text-xs md:text-sm"
                                        >
                                            Checkout
                                        </button>
                                    )}
                                </LoginPopUp>
                            ))}
                    </div>{" "}
                </>
            )}
            <ProductPageBottom />
        </Container>
    );
}

function CartItem({ cartItem }) {
    console.log(cartItem);
    const cart = useCartContext();
    const show = cart.itemInCart(cartItem.id);
    return (
        <div className="relative grid grid-cols-2 mb-5 bg-white shadow-xl p-5 md:px-0 md:shadow-none md:grid-cols-8 md:gap-8 items-center py-4">
            <div className="absolute flex md:items-center md:top-0 m-5 md:m-0 right-0 bottom-0">
                <button onClick={() => cart.removeFromCart(cartItem?.id)}>
                    <img
                        src={require("../assets/trash-can.svg")}
                        alt=""
                        className="h-10 md:h-8"
                    />
                </button>
            </div>
            <div className="h-24 md:border col-span-2 md:w-56 md:h-56 p-5">
                <img
                    className="object-contain h-full w-full"
                    src={cartItem?.images?.[0]?.src}
                    alt={cartItem.name}
                />
            </div>
            <div className="col-span-2 text-k4-gray">
                <h6 className="font-bold text-xs md:text-xl">
                    {cartItem.name}
                </h6>
                <span className="text-sm">{cartItem.sku ?? "-"}</span>
                <div className="text-xs pt-2 h-12 md:h-20 overflow-y-hidden">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: cartItem.short_description ?? "---",
                        }}
                    />
                </div>
            </div>
            <div className="md:flex justify-center col-span-2">
                <div className="border rounded-lg overflow-hidden flex items-center mt-5">
                    <button
                        onClick={() =>
                            cart.incrementDecrementItem(cartItem.id, "d", cartItem.stock_quantity)
                        }
                        className="px-2 md:px-4 py-1 font-bold text-xl text-deep-red border-r"
                    >
                        -
                    </button>
                    <span className="px-1 md:px-3 flex-1 text-k5-gray text-center md:w-20">
                        {show?.quantity}
                    </span>
                    <button
                        onClick={() =>
                            cart.incrementDecrementItem(cartItem.id, "i", cartItem.stock_quantity)
                        }
                        className="px-2 md:px-4 py-1 font-bold text-deep-red text-xl border-l"
                    >
                        +
                    </button>
                </div>
            </div>
            <div className="hidden md:block ">
                <span className="text-lg">
                    {parseToNaira(
                        (show?.quantity || 0) *
                            (cartItem.regular_price - cartItem.price)
                    )}
                </span>
            </div>
            <div className="text-center md:text-left pt-8 pb-2 col-span-2 md:col-span-1">
                <span className="text-sm md:text-lg font-extrabold">
                    {parseToNaira(show.quantity * cartItem.price)}
                </span>
            </div>
        </div>
    );
}
