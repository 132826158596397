import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Link } from 'react-router-dom';

import { useCategories } from '../hooks/fetch_hooks';
import { Loader } from './loader';

export const CategoriesSlider = () => {
    const category = useCategories(50);
    const divider = window.innerWidth < 768 ? 4 : 8;
    const sliderRef = useRef();
    const total = category.data.length;

    const [current, setCurrent] = useState(0);
    const animateToPosition = (pos) => {
        if (sliderRef.current) {
            sliderRef.current.style.transform = `translateX(-${pos * 100}%)`;
        }
    };
    const move = (i) => {
        setCurrent(i);
        animateToPosition(i);
    };
    const counter = useMemo(() => Math.ceil(total / divider) || 1, [total]);
    useEffect(() => {
        const s = setInterval(() => {
            let next = current + 1;
            if (next >= counter) {
                next = 0;
            }
            setCurrent(next);
            animateToPosition(next);
        }, 7000);
        return () => {
            clearInterval(s);
        };
    }, [current, total, counter]);

    return (
        <>
            {category.loading && (
                <div className="py-10">
                    <Loader />
                </div>
            )}
            {!category.loading && !!total && (
                <>
                    <div className="overflow-x-hidden w-full">
                        <div
                            ref={sliderRef}
                            className="flex w-full transition-transform ease-in-out duration-1000"
                        >
                            {Array(counter)
                                .fill(0)
                                .map((_, i) => (
                                    <div className="grid px-5 grid-cols-2 md:grid-cols-4 grid-rows-2 gap-8 w-full flex-shrink-0">
                                        {category.data
                                            .slice(
                                                i * divider,
                                                i * divider + divider
                                            )
                                            .map((c) => (
                                                <Category category={c} />
                                            ))}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="flex justify-center space-x-3 mt-10">
                        {Array(counter)
                            .fill(0)
                            .map((_, i) => (
                                <button
                                    onClick={() => move(i)}
                                    className={`${
                                        current === i
                                            ? "bg-gray-500"
                                            : "bg-white"
                                    } border border-gray-800 h-5 w-5 rounded-full block`}
                                />
                            ))}
                    </div>
                </>
            )}
        </>
    );
};

export function Category({ category, border = true }) {
    return (
        <Link
            to={"/products?category=" + category.id}
            className={`${
                border
                    ? "border border-opacity-25 border-mid-gray"
                    : "pt-8 px-4 pb-4"
            } mx-auto flex flex-col justify-center`}
        >
            <img
                className={`${border && "mb-auto"}`}
                src={category.image?.src}
                alt={category.name}
            />
            <div className="mt-auto py-4">
                <h6 className="font-semibold text-center">{category.name}</h6>
                <button className="text-xs text-black opacity-50 mt-1 mx-auto block">
                    SHOP NOW
                </button>
            </div>
        </Link>
    );
}

export function Product({ product, border = true }) {
    return (
        <Link
            to="/products"
            className={`${
                border
                    ? "flex-1 border px-4 md:border-opacity-25 border-mid-gray"
                    : "pt-8 px-4 pb-4"
            } mx-auto flex flex-col justify-center`}
        >
            <img
                className={`${border && "mb-auto"} h-48 object-contain`}
                src={product.image || product.images?.[0]?.src}
                alt={product.name}
            />
            <div className="mt-auto py-4">
                <h6 className="font-semibold text-center">{product.name}</h6>
                <button className="text-xs text-black opacity-50 mt-1 mx-auto block">
                    SHOP NOW
                </button>
            </div>
        </Link>
    );
}
