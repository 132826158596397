import {GenLayout} from "../components/gen-layout";
import React from "react";

export function PrivacyCookies(){
    return (
        <GenLayout title={'Privacy and Cookies'}>

        </GenLayout>
    )
}
