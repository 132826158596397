import React, {
  createContext,
  useContext,
} from 'react';

import { useCart } from './cart';
import { useCustomer } from './customer';

export const AppContext = createContext();

export function AppProvider({ children }) {
    const cart = useCart();
    const customer = useCustomer();
    return (
        <AppContext.Provider value={{ cart, customer }}>
            {children}
        </AppContext.Provider>
    );
}
export function useCartContext() {
    const { cart } = useContext(AppContext);
    return cart;
}

export function useCustomerContext() {
    const { customer } = useContext(AppContext);
    return customer;
}
