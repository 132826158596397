import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';

import { Container } from '../components/container';
import { Loader } from '../components/loader';
import MedicalInsight from '../components/medical_insight';
import { RecentProduct } from '../components/recent_products';
import { useCartContext } from '../context/app_context';
import {
  useProductDetails,
  useRelatedProducts,
} from '../hooks/fetch_hooks';
import { Product } from './products';

export default function ProductDetails() {
    const [quantity, setQuantity] = useState(1);
    const { product_id } = useParams();
    const { loading, data: product } = useProductDetails(product_id);
    const [selectedImage, setSelectedImage] = useState(0);
    const cart = useCartContext();
    const cartItem = useMemo(() => cart.itemInCart(product.id), [
        product.id,
        cart,
    ]);
    const addToCart = () => {
        if (product.id) {
            cart.addToCart(product, quantity);
        }
    };
    useEffect(() => {
        const oldRecent = localStorage.getItem("recent_products");
        const recent = [];
        if (oldRecent) {
            recent.push(product_id, ...JSON.parse(oldRecent));
        } else {
            recent.push(product_id);
        }
        localStorage.setItem(
            "recent_products",
            JSON.stringify([...new Set(recent)].slice(0, 6))
        );
    }, [product_id]);
    return (
        <>
            {loading && !product.name ? (
                <div className="py-16">
                    <Loader />
                </div>
            ) : (
                <Container>
                    <div className="grid md:grid-cols-2 gap-5 items-center mt-10">
                        <div className="">
                            <img
                                className="mx-auto h-96"
                                src={product.images?.[selectedImage]?.src}
                                alt=""
                            />
                            <div className="md:hidden flex overflow-x-auto w-full space-x-2 mt-10 md:mb-24">
                                {product.images?.map((v, i) => (
                                    <img
                                        key={"pimgk" + v.id}
                                        onClick={() => setSelectedImage(i)}
                                        src={v.src}
                                        alt="star"
                                        className={`${
                                            selectedImage === i
                                                ? "border-2 border-deep-red"
                                                : "border"
                                        } w-20`}
                                    />
                                ))}
                            </div>
                        </div>
                        <div>
                            <h2 className="text-2xl md:text-xl text-center md:text-left font-bold text-k4-gray mb-4">
                                {product.name}
                            </h2>
                            <div className="hidden md:block">
                                <StockStatus status={product.stock_status} />
                                <div className="flex mt-3 mb-3 ">
                                    <StarRating
                                        rating={product.average_rating}
                                    />
                                    <span className="text-xs pl-5 text-mid-gray">
                                        0 customer review
                                    </span>
                                </div>
                            </div>

                            <h6 className="text-2xl text-center md:text-left font-extrabold">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: product.price_html,
                                    }}
                                />
                            </h6>
                            <div className="text-sm mt-3 text-k4-gray">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: product.short_description,
                                    }}
                                />
                            </div>
                            <div className="md:border rounded-lg md:flex items-center min-w-0 px-10 py-3 mt-10 mb-5">
                                {cartItem ? (
                                    <>
                                        <div className=" rounded-sm text-center flex items-stretch mt-5">
                                            <button
                                                onClick={() =>
                                                    cart.incrementDecrementItem(
                                                        product.id,
                                                        "d",
                                                        product.stock_quantity
                                                    )
                                                }
                                                className="px-2 text-white font-bold text-xl bg-deep-red border-r"
                                            >
                                                -
                                            </button>
                                            <span className="px-3 flex-1 text-k5-gray">
                                                {cartItem.quantity}
                                                <br />
                                                <span className="text-xs">
                                                    added to your cart
                                                </span>
                                            </span>
                                            <button
                                                onClick={() =>
                                                    cart.incrementDecrementItem(
                                                        product.id,
                                                        "i",
                                                        product.stock_quantity
                                                    )
                                                }
                                                className="px-2 text-white font-bold bg-deep-red text-xl border-l"
                                            >
                                                +
                                            </button>
                                        </div>
                                        <button
                                            onClick={() =>
                                                cart.removeFromCart(product.id)
                                            }
                                            className="mx-auto block md:mx-3 bg-gray-200 rounded-lg  px-5 py-2 text-k5-gray text-sm mt-2"
                                        >
                                            Remove from cart
                                        </button>
                                    </>
                                ) : (
                                    <div className="flex flex-col md:flex-row items-center">
                                        <div className="mx-auto md:mx-0 border rounded-sm justify-center inline-flex md:flex items-center">
                                            <button
                                                onClick={() =>
                                                    setQuantity(
                                                        quantity > 1
                                                            ? quantity - 1
                                                            : 1
                                                    )
                                                }
                                                className="px-4 md:px-2 text-deep-red text-xl border-r"
                                            >
                                                -
                                            </button>
                                            <span className="px-5 md:px-3">
                                                {quantity}
                                            </span>
                                            <button
                                                onClick={() =>
                                                    setQuantity(quantity + 1)
                                                }
                                                className="px-4 md:px-2 text-deep-red text-xl border-l"
                                            >
                                                +
                                            </button>
                                        </div>
                                        <button
                                            onClick={addToCart}
                                            className="block mt-3 md:mt-0 md:inline-block w-full md:w-auto bg-deep-red text-white font-semibold py-3 md:py-2 px-5  md:mx-5 rounded-lg"
                                        >
                                            Add to cart
                                        </button>
                                    </div>
                                )}
                                <button className="hidden md:inline-block border rounded-full mx-3 p-2 shadow hover:shadow-none">
                                    <img
                                        alt="wishlist"
                                        className="opacity-75"
                                        src={require("./../assets/favorite_border-24px.svg")}
                                    />
                                </button>
                            </div>
                            <div className="text-sm text-k4-gray flex space-x-3">
                                <div>Delivery:</div>
                                <div>
                                    Orders placed after 12PM are delivered next
                                    day. In-store pick up is free. All our
                                    stores are available 24/7.
                                    {/*<span className="text-deep-red font-semibold pl-4">*/}
                                    {/*    View more*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:flex space-x-2 mt-10 mb-24">
                        {product.images?.map((v, i) => (
                            <img
                                key={"pimg" + v.id}
                                onClick={() => setSelectedImage(i)}
                                src={v.src}
                                alt="star"
                                className={`${
                                    selectedImage === i
                                        ? "border-2 border-deep-red"
                                        : "border"
                                } w-20`}
                            />
                        ))}
                    </div>
                    <MoreDetails product={product} />
                </Container>
            )}
            <ProductPageBottom
                product_id={product_id}
                relatedProducts={product.related_ids || []}
            />
        </>
    );
}
function MoreDetails({ product }) {
    const [current, setCurrent] = useState("desc");
    return (
        <div>
            <div className="hidden md:block">
                <ul className="border-b-2 border-deep-red flex ">
                    <li
                        onClick={() => setCurrent("desc")}
                        className={`${
                            current === "desc" ? "bg-deep-red text-white" : ""
                        } px-4 py-2 font-bold text-lg cursor-pointer`}
                    >
                        Description
                    </li>
                    <li
                        onClick={() => setCurrent("add")}
                        className={`${
                            current === "add" ? "bg-deep-red text-white" : ""
                        } px-4 py-2 font-bold text-lg cursor-pointer`}
                    >
                        Additional Information
                    </li>
                    <li
                        onClick={() => setCurrent("rev")}
                        className={`${
                            current === "rev" ? "bg-deep-red text-white" : ""
                        } px-4 py-2 font-bold text-lg cursor-pointer`}
                    >
                        Reviews
                    </li>
                </ul>
                {current === "desc" && <Description product={product} />}
                {current === "add" && (
                    <AdditionalInformation product={product} />
                )}
                {current === "rev" && <Reviews />}
            </div>
            <div className="md:hidden">
                <Description product={product} />
                <AdditionalInformation product={product} />
                <Reviews />
            </div>
        </div>
    );
}
function Description({ product }) {
    const cart = useCartContext();
    const cartItem = useMemo(() => cart.itemInCart(product.id), [
        product.id,
        cart,
    ]);
    const addToCart = () => {
        if (product.id) {
            cart.addToCart(product);
        }
    };
    return (
        <div className="py-5 md:p-5 flex space-x-5">
            <div className="md:w-9/12">
                <h6 className="text-k4-gray text-lg font-semibold mb-4">
                    Description
                </h6>
                <div className="text-k4-gray text-sm">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: product.description,
                        }}
                    />
                </div>
            </div>
            <div className="hidden md:block shadow w-3/12 bg-white p-5">
                <img
                    className="mx-auto"
                    src={product.images?.[0]?.src}
                    alt=""
                />

                <div className="flex">
                    <StarRating rating={product.average_rating} />
                </div>
                <h2 className="text-lg mt-3 font-semibold text-k4-gray mb-4">
                    {product.name}
                </h2>
                <h6 className="text-2xl font-extrabold">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: product.price_html,
                        }}
                    />
                </h6>
                <div className="flex items-center mt-5">
                    {cartItem ? (
                        <div className="text-center">
                            <div className="rounded-sm flex items-stretch mt-5">
                                <button
                                    onClick={() =>
                                        cart.incrementDecrementItem(
                                            product.id,
                                            "d",
                                            product.stock_quantity
                                        )
                                    }
                                    className="px-2 text-white font-bold text-xl bg-deep-red border-r"
                                >
                                    -
                                </button>
                                <span className="px-3 flex-1 text-k5-gray">
                                    {cartItem.quantity}
                                    <br />
                                    <span className="text-xs">
                                        added to your cart
                                    </span>
                                </span>
                                <button
                                    onClick={() =>
                                        cart.incrementDecrementItem(
                                            product.id,
                                            "i",
                                            product.stock_quantity
                                        )
                                    }
                                    className="px-2 text-white font-bold bg-deep-red text-xl border-l"
                                >
                                    +
                                </button>
                            </div>
                            <button
                                onClick={() => cart.removeFromCart(product.id)}
                                className=" text-k5-gray text-sm mt-2"
                            >
                                Remove from cart
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={addToCart}
                            className="bg-deep-red text-white py-2 px-5  mx-5 rounded-lg"
                        >
                            Add to cart
                        </button>
                    )}
                    <button className="border rounded-full mx-3 p-2 shadow hover:shadow-none">
                        <img
                            alt="wishlist"
                            className="opacity-75"
                            src={require("./../assets/favorite_border-24px.svg")}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
}
function AdditionalInformation({ product }) {
    return (
        <div className="md:p-5">
            <h6 className="text-k4-gray text-lg font-semibold mb-4">
                Additional Information
            </h6>
            {!product.attributes?.length && (
                <p className="text-center py-20">No additional information</p>
            )}
            <table className="table table-fixed border w-6/12">
                <tbody>
                    {product?.attributes?.map((v, i) => (
                        <tr key={"p_attr" + i}>
                            <td className="text-k4-gray border p-2 pr-10">
                                {v.name}
                            </td>
                            <td className="font-semibold border p-2 pr-10">
                                {v.options?.join(", ")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
function Reviews() {
    return <div></div>;
}
export function ProductPageBottom({
    showInsight = true,
    product_id,
    relatedProducts = [],
}) {
    const relP = useRelatedProducts(
        JSON.stringify(relatedProducts.slice(0, 6))
    );

    return (
        <>
            <RecentProduct product_id={product_id} />
            {!!(relP.loading || relP.data.length) && (
                <Container bgClass="mt-10 mb-24">
                    <div>
                        <h2
                            className={`text-left text-deep-gray section__title font-black mb-6 md:mb-16`}
                        >
                            Related Products
                        </h2>
                        {relP.loading && (
                            <div className="py-10">
                                <Loader />
                            </div>
                        )}
                        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-5">
                            {relP.data.map((prod) => (
                                <Product product={prod} />
                            ))}
                        </div>
                    </div>
                </Container>
            )}
            {showInsight && <MedicalInsight align="left" />}
        </>
    );
}

function StarRating({ rating = 0 }) {
    const emptyR = 5 - parseInt(rating);
    return (
        <>
            {Array(parseInt(rating))
                .fill(0)
                .map((v) => (
                    <img
                        src={require("./../assets/star-24px.svg")}
                        alt="star"
                        className="w-4"
                    />
                ))}
            {Array(emptyR)
                .fill(0)
                .map((v) => (
                    <img
                        src={require("./../assets/star_border-24px.svg")}
                        alt="star"
                        className="w-4"
                    />
                ))}
        </>
    );
}

function StockStatus({ status }) {
    return (
        <div>
            {status === "instock" ? (
                <span className="text-xs font-semibold text-green-400">
                    In stock
                </span>
            ) : status === "onbackorder" ? (
                <span className="text-xs font-semibold text-orange-400">
                    On backorder
                </span>
            ) : (
                <span className="text-xs font-semibold text-red-400">
                    Out of stock
                </span>
            )}
        </div>
    );
}
