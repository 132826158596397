import {GenLayout} from "../components/gen-layout";
import React from "react";

export function AboutUs() {
    return (
        <GenLayout title={'About Mopheth Pharmacy'}>
            <div className="text-deep-gray text-justify">
                <p className="mb-3">
                    Mopheth is a company with a coherent corporate culture that promotes efficiency and profitability.
                    We
                    strongly believe in providing the best of services and products with the build-up of highly
                    professional
                    work force which meet international standards. Our mission is providing, innovative quality products
                    and
                    excellent services. Our vision is simple; meeting needs.
                </p>
                <p>
                    Mopheth offers a platform that gives you access to essential products and services which are totally
                    genuine and sourced from the most authentic channels. With our vision in mind, Mopheth is passionate
                    about meeting your needs in your comfort zone at ease.
                </p>
                <div className="mt-5">
                    <strong>WHAT MAKES MOPHETH SPECIAL?</strong>
                    <br/>
                    <p className="mt-2">Mopheth brings the ‘Mopheth Experience’ to your own convenience which is an
                        exciting add on feature of
                        all essential products and services offered.
                    </p>
                    <p>
                        <p className="my-4">Here are some of our core values:</p>
                        <p className="mb-2"><strong>BELIEVE IN GOD</strong>: We put God first in all our doings. He is a
                            major foundation of the
                            company.</p>
                        <p className="mb-2"><strong>EXCELLENCE</strong>: All products and services rendered are vetted
                            properly to ensure provision of the
                            highest
                            standard of quality. The customer care service is also top notch, a vital tool of the
                            Mopheth Experience.</p>
                        <p className="mb-2"><strong>LOYALTY</strong>: We believe in commitment to our loyal customers,
                            and loyalty of the staffs and team
                            members
                            to the organization.</p>
                        <p className="mb-2"><strong>INTEGRITY</strong>: Mopheth holds honesty, reliability and oral
                            soundness in high regards.</p>
                        <p className="mb-2"><strong>EQUITY</strong>: We
                            are
                            dedicated to achieving fairness, justice, impartiality and lack of discrimination in all
                            forms</p>
                        <p className="mb-2"><strong>INNOVATION</strong>: We are highly interested and passionate in
                            challenging the status quo in order to
                            meet
                            any and every developing need.</p>
                        Our brand is easily recognizable by the distinctive red and white colors of our logo which is a
                        big white
                        capital letter ‘M’ inscribed within a red circle with a distinctive white element at the top
                        right hand side of
                        the circle. The excellent quality of every essential product and service rendered is also a
                        vital part of our
                        brand
                    </p>
                </div>
            </div>
        </GenLayout>
    )
}
