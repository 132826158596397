import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useCustomerContext } from '../context/app_context';
import { useApiCall } from '../hooks/fetch_hooks';
import { createCustomer } from '../utils/api';
import { ErrorBadge } from './auth_popup';
import { Input } from './input';
import { Loader } from './loader';

export function RegisterWidget({ setPopUp, type, setHaveAccount }) {
    const callApi = useApiCall();
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        username: "",
    });
    const customerContext = useCustomerContext();
    const history = useHistory();
    const handleInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const submit = (e) => {
        e.preventDefault();
        callApi.callApi(
            () => createCustomer(formData),
            (data) => {
                console.log(data);
                customerContext.setUser({ ID: data.id });
                if (type === "login") {
                    setPopUp(false);
                } else if (type === "checkout") {
                    history.push("/checkout");
                }
            },
            (error) => {
                console.log(error);
                setError(error.data.message);
            }
        );
    };
    return (
        <form onSubmit={submit}>
            <h6 className="text-center text-xl font-medium mb-4">Register</h6>
            <ErrorBadge error={error} close={() => setError("")} />
            <Input
                label="First Name"
                value={formData.first_name}
                name="first_name"
                onInput={handleInput}
            />

            <Input
                label="Last Name"
                value={formData.last_name}
                name="last_name"
                onInput={handleInput}
            />

            <Input
                label="Email Address"
                type="email"
                value={formData.email}
                name="email"
                onInput={handleInput}
            />

            <Input
                label="Username"
                value={formData.username}
                name="username"
                onInput={handleInput}
            />

            <Input
                label="Password"
                type="password"
                value={formData.password}
                name="password"
                onInput={handleInput}
            />
            {callApi.loading ? (
                <Loader />
            ) : (
                <>
                    <button
                        type="submit"
                        className="py-3 w-full text-xs bg-deep-red text-center text-white font-semibold px-5 mt-3 rounded-md"
                    >
                        Register
                    </button>
                    <button
                        type="button"
                        onClick={setHaveAccount}
                        className="py-3 w-full text-xs  text-k4-gray"
                    >
                        I have an account
                    </button>
                </>
            )}
        </form>
    );
}
