import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import { Container } from '../components/container';
import { Loader } from '../components/loader';
import { RecentProduct } from '../components/recent_products';
import SideFilter from '../components/side_filter';
import { useCartContext } from '../context/app_context';
import {
  useApiCall,
  useProducts,
} from '../hooks/fetch_hooks';
import {
  fetchCategoryBanner,
  useAirtable,
} from '../utils/airtable';
import { getCategoryDetail } from '../utils/api';
import { getProductBrand } from '../utils/functions';

const per_page = 12;
const domain = [0, 1000000];

export default function ProductsPage({ history, location }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [category, setCategory] = useState({});
    const [search, setSearch] = useState("");
    const [range, setRange] = useState(domain);
    const [attribute, setAttribute] = useState("");
    const callApi = useApiCall();
    const func = useCallback(
        () =>
            fetchCategoryBanner(category?.id || 0).then((data) => {
                return data.map((d) => d.fields);
            }),
        [category]
    );
    const productsRef = useRef(null);
    const image = useAirtable(func);
    const products = useProducts(
        JSON.stringify({
            category: category?.id,
            page: currentPage,
            search,
            per_page,
            min_price: range[0],
            max_price: range[1],
            attribute: attribute,
            attribute_term: 0,
        })
    );
    const [sideMobile, setSideMobile] = useState(false);
    const scrollToProduct = useCallback(() => {
        if (productsRef.current) {
            productsRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [productsRef]);
    useEffect(() => {
        const url = new URLSearchParams(location.search);
        const c = url.get("category");
        const s = url.get("keyword");
        if (s) {
            setSearch(s);
        } else {
            setSearch("");
        }
        if (c) {
            callApi.callApi(
                () => getCategoryDetail(c),
                (data) => {
                    setCategory(data);
                }
            );
        } else {
            setCategory({});
        }
    }, [history, location]);

    useEffect(() => {
        if (products.loading === false) {
            scrollToProduct();
        }
    }, [products.loading]);
    return (
        <>
            <Container bgClass="mb-12">
                <div
                    ref={productsRef}
                    className="md:grid grid-cols-5 gap-6 mt-5"
                >
                    <div
                        className={`${
                            sideMobile ? "visible" : "invisible h-0"
                        } md:visible col-span-1`}
                    >
                        <SideFilter
                            products={products.data}
                            setFilter={setSearch}
                            filter={search}
                            range={range}
                            setRange={setRange}
                            domain={domain}
                            attribute={attribute}
                            setAttribute={setAttribute}
                        />
                    </div>
                    <div className="col-span-4">
                        <div className="text-center md:text-right py-2 md:py-8 px-5 bg-yellow-400">
                            <h6 className="text-2xl md:text-4xl font-bold">
                                {!callApi.loading && (category?.name || "All")}
                            </h6>
                        </div>
                        {image?.[0]?.banner?.[0]?.url && (
                            <img
                                src={image?.[0]?.banner?.[0]?.url}
                                alt=""
                                className="hidden md:block w-full h-56 object-cover mt-4 mb-8"
                            />
                        )}
                        <div className="mt-10 md:flex justify-between items-center">
                            <div className="mt-5 md:mt-0 flex justify-center md:justify-start items-center text-gray-500">
                                <img
                                    className="mr-2 hidden md:inline-block h-8 w-8 opacity-50"
                                    src={require("./../assets/view_module-24px.svg")}
                                    alt=""
                                />
                                <img
                                    className="mr-2 hidden md:inline-block h-8 w-8 opacity-50"
                                    src={require("./../assets/view_agenda-24px.svg")}
                                    alt=""
                                />
                                Showing {products.data.length}{" "}
                                {category?.count
                                    ? `/ ${category?.count}  results`
                                    : "products"}
                            </div>
                            <div className="flex items-center mt-3 md:mt-0">
                                <div className="border md:rounded-full  px-3 py-1">
                                    <span className="text-gray-600">
                                        Sort by:
                                    </span>
                                    <select className="font-bold px-2 ml-2 bg-transparent">
                                        <option>price low to high</option>
                                        <option>price high to low</option>
                                        <option>rating low to high</option>
                                        <option>rating high to low</option>
                                    </select>
                                </div>
                                <button
                                    onClick={() => setSideMobile(!sideMobile)}
                                    className="md:hidden ml-auto bg-gray-300 px-6 py-1"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                        <div className="text-center">
                            {products.loading && <Loader />}
                        </div>
                        {!products.loading && products?.data?.length < 1 && (
                            <p className="text-center my-10 text-base font-light">
                                No products found{" "}
                                {search && "matching the query"}{" "}
                                {category.id && "in the selected category"}
                            </p>
                        )}
                        <div className="grid grid-cols-2 lg:grid-cols-4 col-gap-3 row-gap-5 md:gap-5 mt-10">
                            {!products.loading &&
                                products.data.map((prod) => (
                                    <Product product={prod} />
                                ))}
                        </div>
                        <div className="flex justify-center">
                            {category?.count ? (
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={
                                        (category?.count || 0) / per_page
                                    }
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={(p) =>
                                        setCurrentPage(p.selected + 1)
                                    }
                                    containerClassName={"pagination"}
                                    subContainerClassName={"page"}
                                    activeClassName={"active"}
                                />
                            ) : (
                                <ManualPagination
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Container>

            <RecentProduct />
        </>
    );
}

export function Product({ product }) {
    // const [show, setShow] = useState(false);
    const cart = useCartContext();
    const show = useMemo(() => cart.itemInCart(product.id), [product.id, cart]);
    const addToCart = () => {
        if (product.id) {
            cart.addToCart(product);
        }
    };
    return (
        <div className="shadow-xl text-center flex flex-col hover:shadow-md rounded-lg bg-white p-3 md:p-5">
            <Link to={"/product/details/" + product?.id} className="mt-auto ">
                <img
                    className="mt-2 md:mt-4 mb-2 md:w-9/12 object-contain mx-auto h-40 bg-white"
                    src={product.images?.[0]?.src}
                    alt={""}
                />
                <h5 className="mb-auto text-xs md:text-base">{product.name}</h5>
                <h6 className="font-semibold text-gray-500 my-1">
                    {getProductBrand(product) || "-"}
                </h6>
                <p className="text-base font-bold">
                    <span
                        dangerouslySetInnerHTML={{ __html: product.price_html }}
                    />
                </p>
            </Link>
            {!show && (
                <button
                    onClick={addToCart}
                    className="bg-deep-red text-white w-full mt-5 py-1 rounded font-semibold"
                >
                    Add to cart
                </button>
            )}
            {show && (
                <>
                    <div className=" rounded-sm flex items-center mt-5">
                        <button
                            onClick={() =>
                                cart.incrementDecrementItem(product.id, "d", product.stock_quantity)
                            }
                            className="px-2 text-white font-bold text-xl bg-deep-red border-r"
                        >
                            -
                        </button>
                        <span className="px-3 flex-1 text-k5-gray">
                            {show.quantity}
                            <br />
                            <span className="text-xs">added to your cart</span>
                        </span>
                        <button
                            onClick={() =>
                                cart.incrementDecrementItem(product.id, "i", product.stock_quantity)
                            }
                            className="px-2 text-white font-bold bg-deep-red text-xl border-l"
                        >
                            +
                        </button>
                    </div>
                    <button
                        onClick={() => cart.removeFromCart(product.id)}
                        className="text-k5-gray text-sm mt-2"
                    >
                        Remove from cart
                    </button>
                </>
            )}
        </div>
    );
}

function ManualPagination({ setCurrentPage, currentPage }) {
    return (
        <div className="flex justify-center items-center my-3 pt-5 flex-1 ">
            <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage < 2}
                className={`rounded ${
                    currentPage < 2 ? "bg-pred" : "bg-deep-red"
                } font-bold text-base text-white px-6 py-1`}
            >
                Prev
            </button>
            <div className="rounded-full mx-4 flex items-center justify-center bg-deep-red w-8 h-8 text-white">
                {currentPage}
            </div>
            <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="rounded bg-deep-red font-bold text-base text-white px-6 py-1"
            >
                Next
            </button>
        </div>
    );
}
