import {
  useEffect,
  useReducer,
} from 'react';

import dayjs from 'dayjs';

import { useApiCall } from '../hooks/fetch_hooks';
import { getCustomerDetails } from '../utils/api';

const initState = {
    customer_details: null,
    customer_id: null,
};
const reducer = (state, { type, value }) => {
    switch (type) {
        case "CHANGE": {
            return {
                ...state,
                [value.name]: value.value,
            };
        }
        default: {
            return state;
        }
    }
};

export function useCustomer() {
    const [store, dispatch] = useReducer(reducer, initState);
    const callApi = useApiCall();
    const changeStore = (name, value) => {
        dispatch({ type: "CHANGE", value: { name, value } });
    };
    const fetchCustomerDetails = (user_id) => {
        callApi.callApi(
            () => getCustomerDetails(user_id),
            (data) => {
                changeStore("customer_details", data);
            },
            (error) => {
                changeStore("customer_id", null);
                changeStore("customer_details", null);
            }
        );
    };
    const persistUser = (id) => {
        localStorage.setItem(
            "detail-cus",
            JSON.stringify({
                value: id,
                expiry: dayjs().add(2, "hour").toISOString(),
            })
        );
    };
    const loadUser = () => {
        const user = localStorage.getItem("detail-cus");
        if (user) {
            const data = JSON.parse(user);
            if (dayjs(data.expiry).isAfter(dayjs())) {
                setUser({ ID: data.value });
            } else {
                localStorage.removeItem("detail-cus");
            }
        }
    };
    const setUser = (user_data) => {
        changeStore("customer_id", user_data.ID);
        persistUser(user_data.ID);
        fetchCustomerDetails(user_data.ID);
    };
    const logoutUser = () => {
        console.log("log out");
        localStorage.removeItem("detail-cus");
    };
    useEffect(() => {
        loadUser();
    }, []);
    return {
        store,
        changeStore,
        setUser,
        logoutUser,
    };
}
