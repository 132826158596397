import {GenLayout} from "../components/gen-layout";
import React from "react";

export function ContactUs(){
    return (
        <GenLayout title={'Contact Us'}>
            <div className="md:block flex flex-col items-center">

                <a
                    className="flex mb-3 mt-6"
                    href="mail-to:onlinestore@mophethgroup.com"
                >
                    <img
                        className="mr-2"
                        alt=""
                        src={require("../assets/mail-24px.svg")}
                    />
                    <span>onlinestore@mophethgroup.com</span>
                </a>
                <a className="flex mb-3" href="tel:08081585229">
                    <img
                        className="mr-2"
                        alt=""
                        src={require("../assets/call-24px.svg")}
                    />
                    <span>08081585229</span>
                </a>
                <p className="flex">
                    <img className="mr-2" alt={""} src={require('../assets/location_on-24px.svg')}/>
                    <span>23A, Adeola Odeku Street, Victoria Island, Lagos.</span>
                </p>
            </div>
        </GenLayout>
    )
}
