import React from 'react';

import { Link } from 'react-router-dom';

import { useProducts } from '../hooks/fetch_hooks';
import { Container } from './container';
import { Loader } from './loader';
import { Product } from './products_slider';

export function TrendingProducts() {
    const products = useProducts(
        JSON.stringify({
            category: 155,
            per_page: 8,
        })
    );

    return (
        <Container bgClass="py-12">
            <h2 className="text-center text-deep-gray section__title font-semibold">
                Unmissable offers on our trending products
            </h2>
            {products.loading && (
                <div className="mt-20">
                    {" "}
                    <Loader />
                </div>
            )}

            <div className="w-full vrg-3 overflow-auto pb-5 mt-24 flex justify-between items-stretch space-x-6">
                {products.data.map((prod) => (
                    <div className="w-72 flex items-stretch flex-col flex-shrink-0 h-full">
                        <Product product={prod} />
                    </div>
                ))}
            </div>
            <div className="text-center pt-4">
                <Link
                    to={"/products?category=155"}
                    className="font-semibold text-k-red"
                >
                    View more
                </Link>
            </div>
            {/* {category.loading && (*/}
            {/*    <div className="mt-20">*/}
            {/*        {" "}*/}
            {/*        <Loader />*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*<div className="mt-20 justify-between grid grid-cols-2 md:grid-cols-4 gap-6">*/}
            {/*    {category.data.slice(0, 4).map((cat) => (*/}
            {/*        <Category category={cat} />*/}
            {/*    ))}*/}
            {/*</div>*/}
        </Container>
    );
}
