import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';

import {Link} from 'react-router-dom';

import {Container} from '../components/container';
import {Loader} from '../components/loader';
import MedicalInsight from '../components/medical_insight';
import {RecentProduct} from '../components/recent_products';
import SideFilter from '../components/side_filter';
import {useCartContext} from '../context/app_context';
import {
    useApiCall, useOnSaleProducts,
    useProducts,
} from '../hooks/fetch_hooks';
import {getCategoryDetail} from '../utils/api';
import {getProductBrand} from '../utils/functions';
import ReactPaginate from 'react-paginate';
import {Product} from "./products";

const per_page = 50;
const domain = [0, 1000000];

export default function ProductDealsPage({location}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [range, setRange] = useState(domain);
    const [attribute, setAttribute] = useState('')
    const callApi = useApiCall();
    const products = useOnSaleProducts(JSON.stringify({
        per_page: per_page,
        page: currentPage,
        min_price: range[0],
        max_price: range[1],
    }))

    const [sideMobile, setSideMobile] = useState(false);
    const category = {}
    return (
        <>
            <Container bgClass="mb-12">
                <div className="md:grid grid-cols-5 gap-6 mt-5">
                    <div
                        className={`${
                            sideMobile ? "visible" : "invisible h-0"
                        } md:visible col-span-1`}
                    >
                        <SideFilter
                            products={products.data}
                            setFilter={setSearch}
                            range={range}
                            setRange={setRange}
                            domain={domain}
                            attribute={attribute}
                            setAttribute={setAttribute}
                        />
                    </div>
                    <div className="col-span-4">
                        <div className="text-center md:text-right py-2 md:py-8 px-5 bg-yellow-400">
                            <h6 className="text-2xl md:text-4xl font-bold">
                                Top Deals
                            </h6>
                        </div>
                        {/*<img*/}
                        {/*    src={require("./../assets/mopheth slider 2.jpg")}*/}
                        {/*    alt=""*/}
                        {/*    className="hidden md:block w-full mt-4 mb-8"*/}
                        {/*/>*/}
                        <div className="mt-10 md:flex justify-between items-center">
                            <div
                                className="mt-5 md:mt-0 flex justify-center md:justify-start items-center text-gray-500">
                                <img
                                    className="mr-2 hidden md:inline-block h-8 w-8 opacity-50"
                                    src={require("./../assets/view_module-24px.svg")}
                                    alt=""
                                />
                                <img
                                    className="mr-2 hidden md:inline-block h-8 w-8 opacity-50"
                                    src={require("./../assets/view_agenda-24px.svg")}
                                    alt=""
                                />
                                {/*Showing {products.data.length} /{" "}*/}
                                {/*{category?.count || products.data.length}{" "}*/}
                                {/*results*/}
                            </div>
                            <div className="flex items-center mt-3 md:mt-0">
                                <div className="border md:rounded-full  px-3 py-1">
                                    <span className="text-gray-600">
                                        Sort by:
                                    </span>
                                    <select className="font-bold px-2 ml-2 bg-transparent">
                                        <option>price low to high</option>
                                        <option>price high to low</option>
                                        <option>rating low to high</option>
                                        <option>rating high to low</option>
                                    </select>
                                </div>
                                <button
                                    onClick={() => setSideMobile(!sideMobile)}
                                    className="md:hidden ml-auto bg-gray-300 px-6 py-1"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                        <div className="text-center">
                            {products.loading && <Loader/>}
                        </div>

                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mt-10">
                            {!products.loading &&
                            products.data.map((prod) => (
                                <Product product={prod}/>
                            ))}
                        </div>
                        <div className="flex justify-center">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={(100) / per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={(p) => setCurrentPage(p.selected)}
                                containerClassName={'pagination'}
                                subContainerClassName={'page'}
                                activeClassName={'active'}
                            />
                        </div>

                    </div>
                </div>
            </Container>

            <RecentProduct/>
            </>
    );
}
