import Airtable from "airtable";
import {useCallback, useEffect, useState} from "react";

const client = new Airtable({apiKey: "keyc94gIhGGUJQidL"}).base("appSl6pTf5cxI24TU")

export async function fetchBannerSliders() {
    return client('home banner slider').select().all()
}
export async function fetchCategoryBanner(id){
    return client('category banner').select({filterByFormula:`IF({category_id} = ${id}, TRUE(), FALSE())`}).all()
}

export async function fetchCosmeticSection(){
    return client('cosmetic image').select().all()
}
function fetchBankAccounts(){
    return client('bank accounts').select().all();
}

export function useBankAccounts(){
    const func = useCallback(() => fetchBankAccounts()
        .then(
            (data) => data.map(d => d.fields)
        ), [])
    return useAirtable(func)
}

export function useAirtable(func){
    const [data, setData] = useState([])
    useEffect(() => {
        func().then(
            (data) => {
                setData(data)
            }
        )
    }, [func])
    return data
}
