import {GenLayout} from "../components/gen-layout";
import React from "react";

export function TermsAndCondition(){
    return (
        <GenLayout title={'Terms & Conditions'}>

        </GenLayout>
    )
}
