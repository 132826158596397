import React, {useEffect, useState} from "react";
import {Container} from "../components/container";

export function Services({location}) {
    const [show, setShow] = useState(content[0].key)
    const data = content.find(c => c.key === show)
    useEffect(() => {
        console.log(location)
        const f  = content.find( c => c.key === location.hash.substring(1))
        if(f){
            setShow(f.key)
        }
    }, [location])
    return (
        <Container bgClass="mt-10 mb-20">
            <div className="border-b-2 border-deep-red mt-10 py-4 flex justify-between">
                <div className="text-deep-red flex items-center font-semibold">
                    <img
                        className="inline-block h-5 mr-1"
                        src={require("./../assets/heartbeat (1).svg")}
                        alt=""
                    />
                    Health Checks
                </div>
            </div>
            <p className="text-sm text-k6-gray my-10">
                Our Health Checks are Convenient, Easy and Reliable- We have health check services available in all our
                branches in Lagos. Our Pharmacist is also opened 24/7. Feel free to walk in.
            </p>
            <div>
                <div className="flex space-x-5">
                    {content.map(btn => <button
                        className={`${show === btn.key ? 'delivery' : ''} px-6 py-3 border border-deep-red text-deep-red text-xs`}
                        key={'btn' + btn.key} onClick={() => setShow(btn.key)}>{btn.btnTitle}</button>)}
                </div>
                {data &&
                <div className="border border-deep-red mt-8 p-8 flex text-k6-gray items-center">
                    <div className="w-6/12 mr-5">
                        <h5 className="text-3xl uppercase font-semibold">{data.title}</h5>
                        {
                            data.texts.map(text => <div className="text-xs">
                                <h6 className="font-bold mt-6 mb-3 uppercase">{text.title}</h6>
                                <div>{text.content}</div>
                            </div>)
                        }
                    </div>
                    <img className="flex-1 w-5/12" alt={""} src={data.image}/>
                </div>
                }
            </div>
        </Container>
    )
}

const content = [
    {
        key: 'bp-check',
        title: 'BP Checks',
        btnTitle: 'BP Checks',
        image: require('../assets/Image 81.png'),
        texts: [
            {
                title: 'overview',
                content: <>
                    <p>
                        A blood pressure test is a simple way of checking if your blood pressure is too high or too low.
                        <br/>
                        Blood pressure is the term used to describe the strength with which your blood pushes on the
                        sides of your arteries as it’s pumped around your body.</p>
                    <p>
                        High blood pressure (hypertension) can put a strain on your arteries and organs, which can
                        increase your risk of developing serious problems such as heart attacks and strokes.
                        <br/>
                        Low blood pressure (hypotension) isn’t usually as serious, although it can cause dizziness and
                        fainting in some people.
                    </p>
                </>
            },
            {
                title: 'WHAT YOU SHOULD KNOW ABOUT YOUR BLOOD PRESSURE',
                content: <p>
                    Blood pressure is measured in millimetres of mercury (mmHg) and is given as 2 figures:
                    <br/>
                    Systolic Pressure: the pressure when your heart pushes blood out.
                    <br/>
                    Diastolic Pressure: the pressure when your heart rests between beats.</p>
            },
            {
                title: 'BLOOD PRESSURE RESULT',
                content: <p>
                    Normal blood pressure is considered to be between 90/60mmHg and 120/80mmHg.
                    <br/>
                    High blood pressure is considered to be 140/90mmHg or higher.
                    <br/>
                    Low blood pressure is considered to be 90/60mmHg or lower.
                    <br/>
                    A blood pressure reading between 120/80mmHg and 140/90mmHg could mean you’re at risk of developing
                    high blood pressure if you don’t take steps to keep your blood pressure under control.
                </p>
            }
        ]
    },
    {
        key: 'blood-sugar',
        title: 'BLOOD SUGAR TESTING ',
        btnTitle: 'Blood Sugar',
        image: require('../assets/Image 91.png'),
        texts: [
            {
                title: 'OVERVIEW',
                content: <p>A blood sugar test is a procedure that measures the amount of sugar, or glucose, in your
                    blood.<br/> People with diabetes can also use this test to manage their condition. </p>
            },
            {
                title: 'WHY YOU SHOULD TEST YOUR BLOOD SUGAR',
                content: <p>
                    Blood sugar testing provides useful information for diabetes management.
                    <br/>
                    <strong>It can help you:</strong>
                    <br/>
                    Monitor the effect of diabetes medications on blood sugar levels.
                    <br/>
                    Identify blood sugar levels that are high or low.
                    <br/>
                    Track your progress in reaching your overall treatment goals.
                    <br/>
                    Learn how diet and exercise affect blood sugar levels.
                </p>
            }
        ]
    },
    {
        key: 'bmi-check',
        title: "BMI CHECK",
        btnTitle: 'BMI Checks',

        image: require('../assets/Image 10@2x.png'),
        texts: [
            {
                title: 'OVERVIEW',
                content: <p>Your BMI is a measurement that is a ratio of your weight and height. It’s a good way to
                    gauge whether your weight is in healthy proportion to your height. Knowing your BMI can help you -
                    and your GP - determine any health risks you may face if it’s outside of the healthy range. </p>
            },
            {
                title: 'WHY SHOULD YOU DO A BMI TEST?',
                content: <p>Being overweight can lead to a range of chronic conditions including: <br/>
                    Type 2 Diabetes <br/>
                    High blood pressure <br/> Heart or blood vessel problems <br/>
                    Cardiovascular disease <br/>
                    Musculoskeletal problems<br/>
                    Being underweight can result in other health issues like: <br/>
                    Risk of malnutrition <br/>
                    Osteoporosis <br/>
                    Anaemia
                </p>
            },
            {
                title: 'The BMI scale includes:',
                content: <p>
                    18.5 to 24.9 = Healthy <br/>
                    25 to 29.9 = Overweight <br/>
                    30 and above = Obese <br/>
                    18.4 and under = Underweight
                </p>
            }
        ]
    },
    {
        key: 'cholesterol',
        title: 'CHOLESTEROL CHECK',
        btnTitle: 'Cholesterol Checks',
        image: require('../assets/Image 11.png'),
        texts: [
            {
                title: 'OVERVIEW',
                content: <p>A complete cholesterol test is a blood test that can measure the amount of cholesterol and
                    triglycerides in your blood.</p>
            },
            {
                title: 'WHY DO YOU NEED A CHOLESTEROL TEST?',
                content: <p>High cholesterol usually causes no signs or symptoms.
                    <br/>
                    A complete cholesterol test is done to
                    determine whether your cholesterol is high and estimate your risk of developing heart attacks and
                    other forms of heart disease and diseases of the blood vessels.</p>
            },
            {
                title: 'WHAT DOES THE CHOLESTEROL TEST ENTAIL?',
                content: <p>Total cholesterol. This is a sum of your blood’s cholesterol content. <br/>
                    High-density
                    lipoprotein (HDL) cholesterol. This is called the “good” cholesterol because it helps carry away LDL
                    cholesterol, thus keeping arteries open and your blood flowing more freely.
                    <br/>
                    Low-density lipoprotein
                    (LDL) cholesterol. This is called the “bad” cholesterol. Too much of it in your blood causes the
                    buildup of fatty deposits (plaques) in your arteries (atherosclerosis), that can lead to heart
                    attack. Triglycerides. Triglycerides are a type of fat in the blood.</p>
            },
            {
                title: 'WHO SHOULD GET A CHOLESTEROL TEST?',
                content: <p>
                    Every adult should check their cholesterol every five years
                    <br/>
                    You should check more frequently if:
                    <br/>
                    Have a family history of high cholesterol or heart attacks
                    <br/>
                    Are overweight
                    <br/>
                    Are physically inactive
                    <br/>
                    Have diabetes
                    <br/>
                    Eat an unhealthy diet
                    <br/>
                    Smoke cigarettes
                    <br/>
                    Are a man older than 45 or a woman older than 55
                </p>
            }
        ]
    }
]
