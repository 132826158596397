import React, { useMemo } from 'react';

import { useRelatedProducts } from '../hooks/fetch_hooks';
import { Product } from '../pages/products';
import { Container } from './container';
import { Loader } from './loader';

export function RecentProduct({ product_id = "" }) {
    const recentIDs = useMemo(() => {
        return JSON.parse(localStorage.getItem("recent_products") || "[]");
    }, []);
    const recent = useRelatedProducts(
        JSON.stringify(
            (!!product_id
                ? recentIDs?.filter((r) => r !== product_id)
                : recentIDs
            )?.slice(0, 6)
        )
    );
    return (
        <div>
            {!!(recent.loading || recent.data.length) && (
                <Container bgClass="mt-10 mb-24">
                    <div>
                        <h2
                            className={`text-left text-deep-gray section__title font-black mb-6 md:mb-16`}
                        >
                            Recently Viewed Products
                        </h2>
                        {recent.loading && (
                            <div className="py-10">
                                <Loader />
                            </div>
                        )}
                        <div className="grid md:grid-cols-3 lg:grid-cols-6  gap-5">
                            {recent.data.map((prod) => (
                                <Product product={prod} />
                            ))}
                        </div>
                    </div>
                </Container>
            )}
        </div>
    );
}
