import React, {useEffect, useState} from "react";
import {useCartContext} from "../context/app_context";
import {Container} from "../components/container";
import {InfoBadge} from "../components/auth_popup";
import {parseToNaira} from "../utils/functions";
import {useRelatedProducts, useShippingMethodLine} from "../hooks/fetch_hooks";
import {Loader} from "../components/loader";
import {Redirect} from 'react-router-dom'
import {useBankAccounts} from "../utils/airtable";



export function Order() {
    const {store: cart, changeStore: changeCart} = useCartContext()
    const [info, setInfo] = useState('')
    const banks = useBankAccounts()

    useEffect(() => {
        if (cart.order && cart.order.status === 'completed') {
            setInfo('Your payment was successful')
        }
    }, [cart.order])
    if (!cart.order) {
        return <Redirect to={'/cart'}/>
    }
        const d = [
            {name: "Order Number", value: cart.order.order_key},
            {name: "Date", value: cart.order.date_created},
            {name: "Email", value: cart.order.billing.email},
            {name: "Total", value: parseToNaira(cart.order.total)},
            {name: "Payment Method", value: cart.order.payment_method_title}
        ]

        return (
            <Container bgClass="my-10">
                <InfoBadge info={info} close={() => setInfo('')}/>
                <div className="border-b-2 border-deep-red border-opacity-25 mt-10 py-4 flex justify-between">
                    <div className="text-deep-red flex items-center font-semibold">
                        <img
                            className="inline-block h-5 mr-1"
                            src={require("./../assets/shopping-basket.svg")}
                            alt=""
                        />
                        Order confirmation
                    </div>
                    <span className="text-xl font-semibold text-k6-gray">
                    {parseToNaira(cart.order.total)}
                </span>
                </div>
                <div className="bg-pink-200 px-4 my-2 py-8 md:p-8">
                    <h6 className="text-k4-gray text-4xl text-center font-black">Thank you. Your order has been
                        received</h6>
                </div>
                <div className="bg-white border-deep-red border">
                    <div
                        className="text-mid-gray my-10 flex justify-between divide-x-2 divide-deep-red divide-opacity-50">
                        {d.map(v => <div className="px-8 py-16">
                            <h6 className="uppercase whitespace-no-wrap mb-4">{v.name}</h6>
                            <span className="font-bold">{v.value}</span>
                        </div>)}
                    </div>
                    {
                        cart.order.payment_method === 'bacs' &&
                        <div className="px-10">
                            <h5 className="text-3xl text-mid-gray font-black ">Our bank details</h5>
                            <div className="grid grid-cols-3 gap-10 mt-5  mb-12">
                                {banks.map(bank => (
                                    <div className="flex text-k3-gray">
                                        <img src={require("./../assets/bank-red.svg")} alt="" className=" mr-5"/>
                                        <div>
                                            <p><strong>Account Name:</strong> {bank.account_name}</p>
                                            <p><strong>Account Name:</strong> {bank.account_number}</p>
                                            <p><strong>Bank:</strong> {bank.bank_name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <Products order={cart.order} product_ids={cart.order?.line_items.map(v => v.product_id)}/>
                </div>
            </Container>
        )


}

export const Products = ({product_ids, order}) => {
    const products = useRelatedProducts(JSON.stringify(product_ids))
    return (
        <div className="px-10">
            <div className="flex justify-between text-3xl text-mid-gray font-black mb-10">
                <h5>Order details</h5>
                <h6 className="mt-10">Subtotal</h6>
            </div>
            {products.loading && <Loader/>}
            {
                order.line_items.map(
                    prod => (
                        <div className="flex mb-5 items-center">
                            <img alt="" src={products.data.find(o => o.id === prod.product_id)?.images?.[0]?.src}
                                 className="h-48 w-48 object-contain border border-gray-600"/>
                            <div className="mr-auto ml-5 md:w-84">
                                <h6 className="text-2xl font-black text-k4-gray">{prod.name}</h6>
                                <span className="text-gray-500 text-base my-2 block">SKU: {prod.sku}</span>
                                <span className="text-gray-500 text-base my-2 block">Quantity: {prod.quantity}</span>

                                <p className="text-k2-gray h-20 overflow-hidden"
                                   dangerouslySetInnerHTML={{__html: products.data.find(o => o.id === prod.product_id)?.description}}/>
                            </div>
                            <div className="text-2xl font-black text-deep-gray">
                                {parseToNaira(parseFloat(prod.subtotal))}
                            </div>
                        </div>
                    )
                )
            }
            <hr className="bg-black my-10"/>
            <div className="flex justify-end text-2xl text-deep-gray font-black">
                <h6>SubTotal</h6>
                <p className="ml-24 pl-5">{parseToNaira(order.line_items.map(o => parseFloat(o.subtotal)).reduce((p, c) => p + c, 0))}</p>
            </div>
            <div className="flex text-2xl mt-6 justify-end text-deep-gray font-black">
                <h6>Delivery Charge</h6>
                <p className="ml-24 pl-5">{parseToNaira(order.shipping_total)}</p>
            </div>
            <hr className="bg-black my-10"/>
            <div className="flex justify-end text-3xl text-deep-gray font-black">
                <p>{parseToNaira(order.total)}</p>
            </div>
            <div className="my-20 text-k3-gray  flex divide-x-2 justify-center">
                <div className={"px-32 text-sm"}>
                    <h6 className="text-2xl  font-black">Shipping Details</h6>
                    <p className="text-base font-semibold mt-8 mb-5">{order.shipping.first_name} {order.shipping.last_name}</p>
                    <p className={"w-64"}>{order.shipping.address_1}, {order.shipping.city}</p>
                    <p>{order.shipping.state}</p>
                </div>
                <div className={"px-32"}>
                    { order.shipping_lines?.[1] && <DeliveryDetail method={order.shipping_lines?.[1]}/>}
                </div>
            </div>
        </div>
    )
}

const DeliveryDetail = ({method}) => {
    const meth = useShippingMethodLine(method?.method_id)
    return (
        method?.method_id ?
            <div>
                <h6 className="text-k3-gray text-2xl  font-black">Delivery Details</h6>
                {meth.loading && <Loader/>}
                <div>
                    <p className="mt-5 mb-3">{meth.data.title} - {meth.data.method_title}</p>
                    <p>{parseToNaira(method.total)}</p>
                </div>
            </div>
            : <div/>
    )
}
