import {
    useCallback,
    useEffect, useRef,
    useState,
} from 'react';

import {
    featuredProducts,
    fetchCategories,
    getCustomerOrders,
    getLocalPickupLocation,
    getOnSaleProducts,
    getOtherZoneShippingMethod, getPaymentGatewayDetails,
    getPaymentGateways,
    getProductDetails,
    getProducts,
    getShippingLineDetails,
    getSpecificProductsById,
} from '../utils/api';

export function useObjectData(initialState){
    const [data, setData] = useState(() => {
        if(!!initialState || typeof initialState !== 'object'){
            return {}
        }
        return initialState
    })
    const handleDataChange = (change, all = false) => {
        if(all){
            setData({...change})
        }else{
            setData({
                ...data,
                ...change
            })
        }
    }
    return {
        set: handleDataChange,
        get: data
    }
}

export function useApiCall() {
    const [error, setError] = useState({});
    const [valError, setValError] = useState({});
    const [loading, setLoading] = useState(false);

    const callApi = useCallback(
        async (apiFunction, successCallback, errorCallback) => {
            try {
                setError({});
                setValError({});
                setLoading(true);
                const response = await apiFunction();
                if (response.data) {
                    if (!!successCallback) {
                        successCallback(response.data);
                    }
                } else {
                    successCallback("");
                }
            } catch (err) {
                if (err.response) {
                    // console.warn(err.response.data);
                    setValError(err.response.data || {});
                    if (err.response.data.message) {
                        setError(err.response.data.message);
                        setLoading(true);
                    }
                    if (!!errorCallback) {
                        errorCallback(err.response);
                    }
                }
            } finally {
                setLoading(false);
            }
        },
        []
    );

    return { loading, error, valError, callApi };
}

function useGeneralFetch(getData, search = 'undefinedString', initialData = []) {
    const [data, setData] = useState(initialData);
    const callApi = useApiCall();
    useEffect(() => {
        let isMounted = true
        const fetchData = () => {
            callApi.callApi(
                () => getData(search),
                (data) => {
                   if(isMounted){
                       setData(data)
                   }
                }
            );
        };
        if (isMounted && search !== undefined && search !== null && search !== "[]") {
            fetchData();
        }
       return () => {
            isMounted = false;
        }
    }, [search]);

    return { data, loading: callApi.loading };
}

export function useCategories(parent) {
    return useGeneralFetch(fetchCategories, parent);
}

export function useProducts(search) {
    return useGeneralFetch(getProducts, search);
}

export function useProductDetails(product_id) {
    return useGeneralFetch(getProductDetails, product_id, {});
}

export function useRelatedProducts(product_ids) {
    return useGeneralFetch(getSpecificProductsById, product_ids);
}

export function useOnSaleProducts(total){
    return useGeneralFetch(getOnSaleProducts, total)
}

export function useFeaturedProducts(){
    return useGeneralFetch(featuredProducts)
}

export function useLocalPickupLocation(){
    return useGeneralFetch(getLocalPickupLocation)
}

export function useShippingMethods(){
    return useGeneralFetch(getOtherZoneShippingMethod)
}

export function usePaymentGateWay(){
    return useGeneralFetch(getPaymentGateways)
}
export function usePaymentGatewayDetails(id){
    return useGeneralFetch(() => getPaymentGatewayDetails(id))
}
export function useShippingMethodLine(method){
    return useGeneralFetch(getShippingLineDetails, method, {})
}

export function useCustomerOrders(customer_id){
    return useGeneralFetch(getCustomerOrders, customer_id)
}
