import React, { useState } from 'react';

import {
  Link,
  useHistory,
} from 'react-router-dom';

import { ErrorBadge } from '../components/auth_popup';
import { Container } from '../components/container';
import { Loader } from '../components/loader';
import { useCustomerContext } from '../context/app_context';
import { useApiCall } from '../hooks/fetch_hooks';
import { login } from '../utils/api';

export default function LoginPage() {
    const history = useHistory();
    const customerContext = useCustomerContext();
    const callApi = useApiCall();
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        password: "",
        username: "",
    });

    const handleInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const submit = (e) => {
        e.preventDefault();
        setError("");
        callApi.callApi(
            () => login(formData),
            (data) => {
                // console.log(data);
                if (data.user.data) {
                    customerContext.setUser(data.user.data);
                    history.push("/");
                } else {
                    setError("Invalid login credentials");
                }
            },
            (error) => {
                console.log(error);
                if (error.data.code) {
                    if (error.data.code === "too_many_retries") {
                        setError(
                            "Too many failed login attempts. Try again in a minute"
                        );
                    } else {
                        setError("Invalid login credentials");
                    }
                } else {
                    setError("An error occured");
                }
            }
        );
    };
    return (
        <Container>
            <div className="flex my-16">
                <div className="mx-auto md:w-1/2 py-10 md:border-r px-4 md:px-16">
                    <div className="lg:w-10/12 mx-auto">
                        <h3 className="text-2xl md:text-3xl mb-5 text-center font-black">
                            Returning Customer
                        </h3>
                        <form onSubmit={submit} className="flex flex-col">
                            <div className=" mt-5 mb-4">
                                <ErrorBadge
                                    error={error}
                                    close={() => setError("")}
                                />
                                <InputField
                                    label="Email Address"
                                    type="text"
                                    value={formData.username}
                                    name="username"
                                    onInput={handleInput}
                                />
                                <div className="mb-3" />
                                <InputField
                                    label="Password"
                                    type="password"
                                    value={formData.password}
                                    name="password"
                                    onInput={handleInput}
                                />
                            </div>
                            {callApi.loading ? (
                                <Loader />
                            ) : (
                                <button
                                    type="submit"
                                    className="py-3 w-full text-base bg-deep-red text-center text-white font-semibold px-5 mt-3 rounded-md"
                                >
                                    Log In
                                </button>
                            )}
                            <p className="text-center mt-12">
                                New Customer?{" "}
                                <Link
                                    to="/register"
                                    className="text-blue-500 pl-4 hover:underline"
                                >
                                    Register
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
                <div className="hidden md:block w-1/2 py-10 border-l px-5 lg:px-20">
                    <div className="lg:w-10/12 mx-auto">
                        <h3 className="text-3xl text-center font-black">
                            New Customer
                        </h3>
                        <div className="text-mid-gray my-8">
                            <p>Register for a Mopheth account to enjoy:</p>
                            <ul className="mt-6 space-y-1">
                                <li>Faster checkout</li>
                                <li>Easy order tracking</li>
                                <li>Offers sent directly to you</li>
                                <li>
                                    A favorite list to store all your essentials
                                </li>
                                <li>
                                    Access to online clinics and medicine
                                    ordering
                                </li>
                            </ul>
                        </div>
                        <Link
                            to="/register"
                            className="block py-3 w-full text-base bg-deep-red text-center text-white font-semibold px-5 mt-6 rounded-md"
                        >
                            Register
                        </Link>
                    </div>
                </div>
            </div>
        </Container>
    );
}

const InputField = ({ label, onInput, value, type = "text", name }) => (
    <div>
        <label className="text-sm text-gray-600 mb-1">
            {label}
            <span className="text-deep-red">*</span>{" "}
        </label>
        <input
            required
            value={value}
            onChange={(e) => onInput(name, e.currentTarget.value)}
            type={type}
            className="w-full mb-3 border rounded-md h-12 p-3"
        />
    </div>
);
