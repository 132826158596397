import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

import { useToasts } from 'react-toast-notifications';

import {
  useApiCall,
  useRelatedProducts,
} from '../hooks/fetch_hooks';
import {
  createOrder,
  updateOrder,
} from '../utils/api';
import { config } from '../utils/constants';

const initState = {
    items: [],
    last_item: {},
    pop_up: false,
    checkout_page: "collection",
    checkout_type: "collection",
    order_complete: false,
    order: null,
    creating_order: false,
    collection_location: null,
    billing_details: {},
    shipping_details: {},
    payment_gateway: null,
    payment_status: false,
    paystack_response: null,
    delivery_option: null,
};
const reducer = (state, { type, value }) => {
    switch (type) {
        case "CHANGE": {
            return {
                ...state,
                [value.name]: value.value,
            };
        }
        default: {
            return state;
        }
    }
};
const initializePayment = (
    email,
    first_name,
    last_name,
    amount,
    order_id,
    order_key,
    onComplete
) => {
    let handler = window.PaystackPop.setup({
        email: email,
        firstname: first_name,
        lastname: last_name,
        amount: amount * 100,
        meta: {
            order_id: order_id,
            order_key: order_key,
        },
        key: config.PAYSTACK_KEY,
        onClose: function () {
            console.log(" Paystack pop upWindow closed.");
        },
        callback: function (response) {
            onComplete(response);
        },
    });
    handler.openIframe();
};

export function useCart() {
    const callApi = useApiCall();
    const toast = useToasts();
    const addToCart = (product, quantity = 1) => {
        if (product.id && product.stock_quantity == null) {
            toast.addToast(`Product is currently unavailable`, {
                appearance: "warning",
            });
            return;
        }
        if (product.id) {
            let item = itemInCartIndex(product.id);
            if (item >= 0) {
                store.items[item].quantity += quantity;
            } else {
                item = store.items.length;
                store.items.push({
                    p_id: product.id,
                    quantity,
                });
            }
            if (
                product.stock_quantity &&
                product.stock_quantity < store.items[item].quantity
            ) {
                toast.addToast(
                    `Exceeding product stock quantity of ${
                        product.stock_quantity ?? 0
                    }`,
                    { appearance: "warning" }
                );
                return false;
            } else {
                changeStore("items", store.items);
                changeStore("last_item", product);
                changeStore("pop_up", true);
            }
        }
    };
    const incrementDecrementItem = (product_id, type = "i", stock_quantity) => {
        let item = itemInCartIndex(product_id);
        const storeItem = JSON.parse(JSON.stringify(store.items));
        if (item >= 0) {
            if (type === "i") {
                storeItem[item].quantity += 1;
            } else if (type === "d" && storeItem[item].quantity > 1) {
                storeItem[item].quantity -= 1;
            }
            console.log(
                store.items[item].quantity,
                stock_quantity,
                stock_quantity < store.items[item].quantity
            );

            if (stock_quantity <= store.items[item].quantity) {
                console.log("sfasdf");
                toast.addToast(
                    `Exceeding product stock quantity of ${
                        stock_quantity ?? 0
                    }`,
                    { appearance: "warning" }
                );
                storeItem[item].quantity = stock_quantity;
                // debugger;
            } else {
                changeStore("items", storeItem);
            }
        }
    };
    const itemInCartIndex = (product_id) => {
        return store.items.findIndex((s) => s.p_id === product_id);
    };
    const itemInCart = (product_id) => {
        return store.items.find((s) => s.p_id === product_id);
    };
    const removeFromCart = (product_id) => {
        changeStore(
            "items",
            store.items.filter((s) => s.p_id !== product_id)
        );
    };
    const completeOrder = async (response, order_id) => {
        const data = {
            status: "completed",
            set_paid: true,
            transaction_id: response.trxref,
        };
        changeStore("creating_order", true);
        try {
            const res = await updateOrder(order_id, data);
            changeStore("order", res.data);
            changeStore("order_complete", true);
        } finally {
            changeStore("creating_order", false);
        }
    };

    const makeOrder = (user_id) => {
        const data = {
            payment_method: store.payment_gateway.id,
            payment_method_title: store.payment_gateway.method_title,
            billing: {
                country: "NG",
                ...store.billing_details,
            },
            shipping: {},
            line_items: store.items.map((si) => ({
                product_id: si.p_id,
                quantity: si.quantity,
            })),
            shipping_lines: [
                {
                    method_id: store.payment_gateway.id,
                    method_title: store.payment_gateway.method_title,
                    total: "0",
                },
            ],
        };
        // data.set_paid = store.payment_status
        if (store.checkout_type === "collection") {
            const location = store.collection_location.name.split("|");
            data.shipping = {
                first_name: store.billing_details.first_name,
                last_name: store.billing_details.last_name,
                address_1: location[1] + "," + location[2],
                city: location?.[3],
                state: location?.[4],
                country: "NG",
            };
        } else {
            data.shipping = {
                country: "NG",
                ...store.shipping_details,
            };
            data.shipping_lines[1] = {
                method_id: store.delivery_option.id,
                method_title: store.delivery_option.method_title,
                total: store.delivery_option.settings?.cost?.value || "0",
            };
        }

        if (user_id) {
            data.customer_id = user_id;
        }
        changeStore("creating_order", true);
        callApi.callApi(
            () => createOrder(data),
            (data) => {
                changeStore("creating_order", false);
                changeStore("order", data);
                if (store.payment_gateway.id === "paystack") {
                    initializePayment(
                        data.billing.email,
                        data.billing.first_name,
                        data.billing.last_name,
                        parseFloat(data.total),
                        data.id,
                        data.order_key,
                        (response) => {
                            changeStore("payment_status", true);
                            completeOrder(response, data.id);
                        }
                    );
                } else {
                    changeStore("order_complete", true);
                }
            },
            (err) => {
                alert("An error occurred. Try again");
                changeStore("creating_order", false);
            }
        );
    };

    const [store, dispatch] = useReducer(reducer, initState);
    const notFirstCall = useRef();
    const changeStore = useCallback(
        (name, value) => {
            dispatch({ type: "CHANGE", value: { name, value } });
        },
        [dispatch]
    );
    const cartProducts = useRelatedProducts(
        JSON.stringify(store.items.map((v) => v.p_id))
    );

    useEffect(() => {
        console.log("CART PRODUCT", cartProducts?.data);
        // TODO remove on the item to remove ones that didnt come back
        // const available_ids = cartProducts?.data?.map( c => (c.id))
        const d = cartProducts?.data?.forEach((c) => {
            if (
                c.stock_quantity &&
                c.stock_quantity < itemInCart(c.id).quantity
            ) {
                console.log(
                    "here",
                    c.stock_quantity,
                    itemInCart(c.id).quantity
                );
                addToCart(c, parseInt(c.stock_quantity));
            }
            if (c.status !== "publish") {
                removeFromCart(c.id);
            }
        });
    }, [cartProducts]);
    const cartTotal = useMemo(() => {
        return cartProducts.data.reduce((ac, item) => {
            const it = itemInCart(item.id);
            if (it) {
                return ac + it.quantity * item.price;
            }
            return ac;
        }, 0);
    }, [store.items, cartProducts.data]);
    const totalAmount = useMemo(() => {
        return (
            cartTotal +
            parseFloat(
                store.checkout_type === "collection"
                    ? "0"
                    : store.delivery_option?.settings?.cost?.value
            )
        );
    }, [
        store.items,
        cartProducts.data,
        store.delivery_option,
        store.checkout_type,
    ]);
    useEffect(() => {
        const loadCartItems = () => {
            const items = localStorage.getItem("cart-items");
            if (items) {
                changeStore("items", JSON.parse(items));
            }
        };
        loadCartItems();
    }, []);
    useEffect(() => {
        if (notFirstCall.current) {
            localStorage.setItem("cart-items", JSON.stringify(store.items));
        } else {
            notFirstCall.current = true;
        }
    }, [store]);
    return {
        store,
        changeStore,
        addToCart,
        itemInCart,
        incrementDecrementItem,
        removeFromCart,
        cartProducts,
        cartTotal,
        totalAmount,
        makeOrder,
    };
}
