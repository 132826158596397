import React, {useEffect} from "react";
import {GenLayout} from "../components/gen-layout";
import {useCustomerOrders} from "../hooks/fetch_hooks";
import {useCustomerContext} from "../context/app_context";
import {Loader} from "../components/loader";
import {parseToNaira} from "../utils/functions";
import {Link} from "react-router-dom";
export function UserArea({history}){
    const customer = useCustomerContext()
    const orders = useCustomerOrders(customer.store.customer_details?.id || undefined)
    useEffect(() => {
        const user = localStorage.getItem("detail-cus")
        if(!user){
                history.push('/login')
        }
    }, [])
    return (
        <GenLayout padding={false} title={'Customer member area'}>
            <div className="border-b font-semibold pb-5 pt-6 text-mid-gray pl-6 border-gray-500">
                Order History
            </div>
            {orders.loading && <Loader/>}
            <div className="py-4 mt-8 border-b-2 border-gray-500 mb-3 font-semibold grid grid-cols-5 ">
                <p>Billing Details</p>
                <p className="text-center">No of items</p>
                <p>Payment method</p>
                <p>Status</p>
                <p>Total amount</p>
            </div>
            {
                !!orders.data.length || <p className="text-center my-8">No orders yet</p>
            }
            <div className="divide-y text-deep-gray">
                {
                orders.data.map( order => (
                    <Link to={{pathname:'/order/'+order.id, data:order}} className="py-4 cursor-pointer grid grid-cols-5 ">
                        <p>#{order.id} {order.billing.first_name} {order.billing.last_name}</p>
                        <p className="text-center">{order.line_items.length}</p>
                        <p>{order.payment_method_title}</p>
                        <p>{order.status}</p>
                        <p>{parseToNaira(order.total)}</p>
                    </Link>
                ))
            }
            </div>
        </GenLayout>
    )
}
