import React, {useEffect, useRef} from "react";
import {useOutsideAlerter} from "./cart_popup";

export function Prescription() {
    const ref = useRef()
    useOutsideAlerter(ref, () => {
        document.getElementById('prescription').classList.add('invisible')
    })

    return (
        <div  id="prescription" className="invisible fixed high-z top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div ref={ref} className="border-2 bg-white max-w-lg px-16 py-12 text-k10-gray">
                <h6 className="text-xl font-black">Do you want to refill a prescription?</h6>
                <p className="text-sm mt-3 mb-5">You have a prescription from a doctor and would want to refill, kindly chat with our pharmacist</p>
                <a href={'https://wa.me/2348188387165'} className="inline-block text-center w-full py-2 mb-12 bg-deep-red text-white rounded font-semibold shadow">Chat with a pharmacist</a>

                <h6 className="text-xl font-black">Don't have a prescription?</h6>
                <p className="text-sm mt-3 mb-5">You don't have any prescription, kindly chat with our pharmacist</p>
                <a href={'https://wa.me/2348188387165'} className="inline-block text-center w-full py-2 border border-deep-red text-deep-red font-semibold rounded">Chat with a pharmacist</a>
            </div>
        </div>
    )
}
