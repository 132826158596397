import React, {useEffect, useState,} from 'react';

export const ImageSlider = ({
                                images = [],
                                duration = 8000,
                                animationClass,
                            }) => {
    const [data, setData] = useState({
        current: 0,
        last: images.length - 1,
    });
    useEffect(() => {
        const s = setInterval(() => {
            let next = data.current + 1;
            if (next >= images.length) {
                next = 0;
            }
            setData({
                current: next,
                last: data.current,
            });
        }, duration);
        return () => {
            clearInterval(s);
        };
    }, [data, images.length, duration]);
    return (
        <>
            <div className="relative overflow-x-hidden w-full">
                {images.map((comp, i) => (
                    <div
                        onClick={() => {
                            window.location = comp.url || '/'
                        }}
                        key={"imgslid" + i}
                        className={` ${
                            i === data.current
                                ? `z-20 ${animationClass}-enter`
                                : `z-0 ${animationClass}-leave`
                        } ${i === 0 ? "relative" : "absolute"} 
                        ${
                            [data.last, data.current].includes(i)
                                ? "opacity-100"
                                : "opacity-0"
                        } left-0 top-0`}
                    >
                        <img
                            src={comp.image[0]?.url}
                            alt="hero"
                            className="object-fill origin-center object-center w-full h-auto"
                        />
                        <div
                            className={(comp.color || "text-white") + " absolute top-0 bottom-0 left-0 hidden md:flex flex-col justify-center items-start pl-16 w-1/2 z-20"}>
                            <h3 className="text-4xl leading-tight font-extrabold  ">
                                {comp.title}
                            </h3>
                            <p className=" text-lg mt-8 mb-4">
                                {comp.subtitle}
                            </p>
                            <button className="inline-block underline font-semibold">
                                {comp?.['button-text']}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
