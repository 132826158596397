import React from "react";
import {Redirect} from 'react-router-dom'
import {Container} from "../components/container";
import {InfoBadge} from "../components/auth_popup";
import {parseToNaira} from "../utils/functions";
import {Products} from "./order";
import {usePaymentGatewayDetails} from "../hooks/fetch_hooks";
import {useBankAccounts} from "../utils/airtable";



export function OrderDetail({history}) {
    const order = history.location.data
    const banks = useBankAccounts()
    if (!order) {
        return <Redirect to="/user"/>
    }

    const d = [
        {name: "Order Number", value: order.order_key},
        {name: "Date", value: order.date_created},
        {name: "Email", value: order.billing.email},
        {name: "Total", value: parseToNaira(order.total)},
        {name: "Payment Method", value: order.payment_method_title}
    ]

    return (
        <Container bgClass="my-10">

            <div className="border-b-2 border-deep-red border-opacity-25 mt-10 py-4 flex justify-between">
                <div className="text-deep-red flex items-center font-semibold">
                    <img
                        className="inline-block h-5 mr-1"
                        src={require("./../assets/shopping-basket.svg")}
                        alt=""
                    />
                    Order confirmation
                </div>
                <span className="text-xl font-semibold text-k6-gray">
                    {parseToNaira(order.total)}
                </span>
            </div>

            <div className="bg-white border-deep-red border">
                <div
                    className="text-mid-gray my-10 flex justify-between divide-x-2 divide-deep-red divide-opacity-50">
                    {d.map(v => <div className="px-8 py-16">
                        <h6 className="uppercase whitespace-no-wrap mb-4">{v.name}</h6>
                        <span className="font-bold">{v.value}</span>
                    </div>)}
                </div>
                {
                    order.payment_method === 'bacs' && order.status === 'pending' &&
                    <div className="px-10">
                        <h5 className="text-3xl text-mid-gray font-black ">Our bank details</h5>
                        <div className="grid grid-cols-3 gap-10 mt-5  mb-12">
                            {banks.map(bank => (
                                <div className="flex text-k3-gray">
                                    <img src={require("./../assets/bank-red.svg")} alt="" className=" mr-5"/>
                                    <div>
                                        <p><strong>Account Name:</strong> {bank.account_name}</p>
                                        <p><strong>Account Name:</strong> {bank.account_number}</p>
                                        <p><strong>Bank:</strong> {bank.bank_name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <Products order={order} product_ids={order?.line_items.map(v => v.product_id)}/>
            </div>
        </Container>
    )
}
