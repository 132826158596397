import React, { useState } from 'react';

import {
  Link,
  useHistory,
} from 'react-router-dom';

import { useCustomerContext } from '../context/app_context';
import { useApiCall } from '../hooks/fetch_hooks';
import { login } from '../utils/api';
import { PopUp } from './cart_popup';
import { Input } from './input';
import { Loader } from './loader';
import { RegisterWidget } from './register';

function LoginPopUpBody({ type = "checkout", popup, setPopUp }) {
    const [haveAccount, setHaveAccount] = useState("login");
    const history = useHistory();
    const customerContext = useCustomerContext();
    const callApi = useApiCall();
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        password: "",
        username: "",
    });

    const handleInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const submit = (e) => {
        e.preventDefault();
        callApi.callApi(
            () => login(formData),
            (data) => {
                if (data?.user?.data) {
                    customerContext.setUser(data.user.data);
                    if (type === "login") {
                        setPopUp(false);
                    } else if (type === "checkout") {
                        history.push("/checkout");
                    }
                } else {
                    setError("Invalid login credentials");
                }
            },
            (error) => {
                console.log(error);
                if (error.data.code) {
                    if (error.data.code === "too_many_retries") {
                        setError(
                            "Too many failed login attempts. Try again in a minute"
                        );
                    } else {
                        setError("Invalid login credentials");
                    }
                } else {
                    setError("An error occured");
                }
            }
        );
    };
    const accountCheckBox = (
        <>
            <label className="text-sm flex items-center mt-4 text-mid-gray">
                <input
                    name="account"
                    checked={haveAccount === "login"}
                    onClick={() => setHaveAccount("login")}
                    type="radio"
                    className="w-4 h-4 mr-2"
                />
                I have a Mopheth's account
            </label>
            <label className="text-sm flex items-center mb-4 text-mid-gray">
                <input
                    name="account"
                    checked={haveAccount === "no-account"}
                    onClick={() =>
                        setHaveAccount(
                            type === "checkout" ? "no-account" : "register"
                        )
                    }
                    type="radio"
                    className="w-4 h-4 mr-2"
                />
                I don't have a Mopheth's account
            </label>
        </>
    );
    return (
        <div className="flex flex-col px-5">
            {haveAccount === "login" && (
                <form onSubmit={submit} className="flex flex-col">
                    <ErrorBadge error={error} close={() => setError("")} />
                    <Input
                        label="Email Address"
                        type="text"
                        value={formData.username}
                        name="username"
                        onInput={handleInput}
                    />
                    {accountCheckBox}
                    <Input
                        label="Password"
                        type="password"
                        value={formData.password}
                        name="password"
                        onInput={handleInput}
                    />

                    <p className="text-right text-sm">Forgot password?</p>
                    {callApi.loading ? (
                        <Loader />
                    ) : (
                        <button
                            type="submit"
                            className="py-3 w-full text-xs bg-deep-red text-center text-white font-semibold px-5 mt-3 rounded-md"
                        >
                            Login
                        </button>
                    )}
                    {type !== "checkout" && (
                        <Link
                            to={"/cart"}
                            onClick={() => setPopUp(false)}
                            className="py-3 text-xs bg-deep-red text-center text-white font-semibold px-5 mt-3 rounded-md"
                        >
                            View basket & checkout
                        </Link>
                    )}
                    <button
                        type="button"
                        onClick={() => setPopUp(false)}
                        className="py-3 text-xs  text-k4-gray"
                    >
                        Continue shopping
                    </button>
                </form>
            )}
            {haveAccount === "no-account" && (
                <>
                    <h6 className="text-center text-xl font-medium mb-4">
                        Login
                    </h6>
                    {accountCheckBox}
                    <Link
                        to={"/checkout"}
                        className="py-3 text-xs bg-deep-red text-center text-white font-semibold px-5 mt-3 rounded-md"
                    >
                        Checkout as a guest
                    </Link>
                    <button
                        onClick={() => setHaveAccount("register")}
                        className="py-3 text-xs bg-blue-700 text-center text-white font-semibold px-5 mt-5 mb-6 rounded-md"
                    >
                        Register
                    </button>
                </>
            )}
            {haveAccount === "register" && (
                <RegisterWidget
                    setPopUp={setPopUp}
                    type={type}
                    setHaveAccount={() => setHaveAccount("login")}
                />
            )}
        </div>
    );
}

export function LoginPopUp({ type = "checkout", children }) {
    return (
        <PopUp
            type="center"
            bg={type === "login"}
            body={(b) => <LoginPopUpBody type={type} {...b} />}
            children={children}
        />
    );
}

export function ErrorBadge({ error, close }) {
    return error ? (
        <div className="relative flex items-center justify-center mb-3 pl-3 pr-5 text-center text-white rounded-sm py-1 font-semibold bg-red-500 text-xs">
            <span>{error}</span>
            {close && (
                <button
                    type="button"
                    onClick={close}
                    className="absolute right-0 mr-3 text-base font-black"
                >
                    ✕
                </button>
            )}
        </div>
    ) : (
        <span />
    );
}

export function InfoBadge({ info, close }) {
    return info ? (
        <div className="relative w-10/12 mx-auto flex items-center justify-center mb-3 pl-3 pr-5 text-center text-blue-700 rounded-sm py-6 font-semibold bg-blue-300 bg-opacity-50 text-base">
            <span>{info}</span>
            {close && (
                <button
                    type="button"
                    onClick={close}
                    className="absolute right-0 mr-3 text-base font-black"
                >
                    ✕
                </button>
            )}
        </div>
    ) : (
        <span />
    );
}
