import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Link } from 'react-router-dom';

import { Loader } from '../../components/loader';
import {
  useCartContext,
  useCustomerContext,
} from '../../context/app_context';
import {
  usePaymentGateWay,
  useShippingMethods,
} from '../../hooks/fetch_hooks';
import { parseToNaira } from '../../utils/functions';

export function Delivery() {
    return (
        <div>
            <DeliveryAddress />
        </div>
    );
}

function DeliveryAddress() {
    const { store, changeStore } = useCartContext();
    const { store: customer } = useCustomerContext();
    const [asBilling, setAsBilling] = useState(false);
    const [shipping, setShipping] = useState({});
    const [billing, setBilling] = useState({});
    const [extra, setExtra] = useState({});
    const billing_inputs = useMemo(
        () => [
            {
                label: "First Name",
                short: "first_name",
                default: customer.customer_details?.first_name,
                required: true,
            },
            {
                label: "Last Name",
                short: "last_name",
                default: customer.customer_details?.last_name,
                required: true,
            },
            {
                label: "Email",
                short: "email",
                default: customer.customer_details?.email,
                required: true,
                type: "email",
            },
            {
                label: "Phone No.",
                short: "phone",
                required: true,
                type: "number",
            },
            { label: "Address 1", short: "address_1", required: true },
            { label: "Address 2", short: "address_2" },
            { label: "City", short: "city", required: true },
            { label: "State", short: "state", required: true },
        ],
        [customer]
    );
    const shipping_inputs = useMemo(
        () => [
            {
                label: "First Name",
                short: "first_name",
                default: customer.customer_details?.first_name,
                required: true,
            },
            {
                label: "Last Name",
                short: "last_name",
                default: customer.customer_details?.last_name,
                required: true,
            },
            { label: "Address 1", short: "address_1", required: true },
            { label: "Address 2", short: "address_2" },
            { label: "City", short: "city", required: true },
            { label: "State", short: "state", required: true },
        ],
        [customer]
    );

    const extra_inputs = useMemo(
        () => [
            {
                label: "Email",
                short: "email",
                default: customer.customer_details?.email,
                required: true,
                type: "email",
            },
            {
                label: "Phone No.",
                short: "phone",
                required: true,
                type: "number",
            },
        ],
        [customer]
    );
    useEffect(() => {
        const b = {};
        const s = {};
        const e = {};
        billing_inputs.forEach((bi) => {
            b[bi.short] =
                store.billing_details[bi.short] ||
                billing[bi.short] ||
                bi.default ||
                "";
        });
        shipping_inputs.forEach((si) => {
            s[si.short] =
                store.shipping_details[si.short] ||
                shipping[si.short] ||
                si.default ||
                "";
        });
        extra_inputs.forEach((ei) => {
            e[ei.short] =
                store.billing_details[ei.short] ||
                extra[ei.short] ||
                ei.default ||
                "";
        });
        setBilling(b);
        setShipping(s);
        setExtra(e);
    }, [store, billing_inputs, shipping_inputs, extra_inputs]);
    const nextStep = (e) => {
        e.preventDefault();
        let bill = billing;
        if (asBilling) {
            bill = {
                ...billing,
                ...shipping,
                ...extra,
            };
        }
        changeStore("billing_details", bill);
        changeStore("shipping_details", shipping);
        changeStore("checkout_page", "delivery_option");
    };
    return (
        <form onSubmit={nextStep}>
            <div className="p-5 bg-white shadow-md  lg:w-6/12 mx-auto m-8">
                <h6 className="font-semibold text-xl mb-5 text-k4-gray">
                    Delivery Address
                </h6>
                {shipping_inputs.map((i) => (
                    <div className="flex items-center mb-3">
                        <label className="w-3/12 text-sm text-k4-gray">
                            {i.label}
                        </label>
                        {shipping[i.short] !== undefined && (
                            <input
                                value={shipping[i.short]}
                                onChange={(e) =>
                                    setShipping({
                                        ...shipping,
                                        [i.short]: e.currentTarget.value,
                                    })
                                }
                                required={i.required}
                                className="w-9/12 px-3 border h-10"
                            />
                        )}
                    </div>
                ))}
                {asBilling && (
                    <>
                        {extra_inputs.map((i) => (
                            <div className="flex items-center mb-3">
                                <label className="w-3/12 text-sm text-k4-gray">
                                    {i.label}
                                </label>
                                {extra[i.short] !== undefined && (
                                    <input
                                        value={extra[i.short]}
                                        onChange={(e) =>
                                            setExtra({
                                                ...extra,
                                                [i.short]:
                                                    e.currentTarget.value,
                                            })
                                        }
                                        required={i.required}
                                        type={i.type || "text"}
                                        className="w-9/12 px-3 border h-10"
                                    />
                                )}
                            </div>
                        ))}
                    </>
                )}
                <div className="flex justify-center">
                    <label className="text-k4-gray mx-auto">
                        <input
                            checked={asBilling}
                            onChange={(e) =>
                                setAsBilling(e.currentTarget.checked)
                            }
                            type="checkbox"
                        />{" "}
                        Use as billing address
                    </label>
                </div>
            </div>
            {!asBilling && (
                <div className="p-5 bg-white shadow-md  lg:w-6/12 mx-auto m-8">
                    <h6 className="font-semibold text-xl mb-5 text-k4-gray">
                        Billing Address
                    </h6>
                    {billing_inputs.map((i) => (
                        <div className="flex items-center mb-3">
                            <label className="w-3/12 text-sm text-k4-gray">
                                {i.label}
                            </label>
                            {billing[i.short] !== undefined && (
                                <input
                                    value={billing[i.short]}
                                    onChange={(e) =>
                                        setBilling({
                                            ...billing,
                                            [i.short]: e.currentTarget.value,
                                        })
                                    }
                                    required={i.required}
                                    type={i.type || "text"}
                                    className="w-9/12 p-3 border h-10"
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}

            <div className="mt-12 mb-6 flex justify-center space-x-5">
                <Link
                    to="/cart"
                    className="py-2 text-deep-red px-12 mr-4 border border-deep-red rounded-md text-sm"
                >
                    Go back
                </Link>

                <button
                    type={"submit"}
                    className="bg-deep-red rounded-md font-semibold text-white px-8 py-2 text-sm"
                >
                    Continue
                </button>
            </div>
        </form>
    );
}

export function DeliveryOption({ next }) {
    const delivery_options = useShippingMethods();
    const { changeStore } = useCartContext();
    const handleNext = (selected_option) => {
        changeStore("delivery_option", selected_option);
        changeStore("checkout_page", "payment");
    };
    return (
        <div className="md:w-10/12 mx-auto">
            <h5 className="font-semibold text-xl mb-12 text-k4-gray">
                Select delivery option
            </h5>
            {delivery_options.loading && <Loader />}
            {delivery_options.data
                .filter((v) => v.enabled)
                .map((dopt) => (
                    <div className="grid grid-cols-2 md:flex  justify-between items-center border-t border-b py-4 md:border-0 md:mb-4 ">
                        <div className="md:flex justify-between items-center md:space-x-8">
                            <h6 className="text-base md:w-56 md:text-lg font-bold text-k2-gray">
                                {dopt.title}
                            </h6>
                            {/*<div className="text-xs text-k4-gray">{dopt.settings?.cost?.description}</div>*/}
                        </div>

                        <div className="text-right md:text-center text-base md:text-xl font-bold text-k2-gray">
                            {parseToNaira(dopt.settings?.cost?.value)}
                        </div>
                        <div className="col-span-2 mt-4 md:mt-0 ml-auto md:ml-0">
                            <button
                                onClick={() => handleNext(dopt)}
                                className="bg-pink-100 text-deep-red font-semibold text-xs md:text-lg rounded-lg py-2 px-8"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                ))}
        </div>
    );
}

const payment_options = [
    {
        icon: require("./../../assets/credit-card (1).svg"),
        name: "paystack",
    },
    {
        icon: require("./../../assets/bank-red.svg"),
        name: "bacs",
    },
];

export function DeliveryPaymentOption() {
    const paymentGateway = usePaymentGateWay();
    const { store, changeStore, totalAmount } = useCartContext();
    const handleChange = (option) => {
        changeStore("payment_gateway", option);
    };
    return (
        <div className="md:w-10/12 mx-auto">
            <h5 className="font-semibold text-xl mb-12 text-k4-gray">
                Select payment option
            </h5>
            {paymentGateway.loading && <Loader />}
            {paymentGateway.data
                .filter((v) => v.enabled)
                .map((dopt) => (
                    <div
                        role="button"
                        onClick={() => handleChange(dopt)}
                        className="flex justify-between items-center mb-4 r"
                    >
                        <h6 className="w-64 lg:w-84 text-base md:text-xl font-bold text-k2-gray flex items-center">
                            <span
                                className={
                                    "block h-6 w-6 mr-5 border-2 rounded-full flex justify-center items-center"
                                }
                            >
                                {store.payment_gateway?.id === dopt.id && (
                                    <span
                                        className={
                                            "h-3 w-3 block rounded-full bg-black"
                                        }
                                    />
                                )}
                            </span>
                            <img
                                src={
                                    payment_options.find(
                                        (v) => v.name === dopt.id
                                    )?.icon
                                }
                                alt=""
                                className="h-8 mr-3"
                            />
                            {dopt?.title || dopt.method_title}
                        </h6>
                        <div className="w-72 hidden md:block text-xs text-k4-gray">
                            {dopt.description}
                        </div>
                        <div className="text-center text-base md:text-xl font-bold text-k2-gray">
                            {parseToNaira(totalAmount)}
                        </div>
                    </div>
                ))}
        </div>
    );
}
