import React, { useEffect } from 'react';

import { Container } from '../../components/container';
import { FullScreenLoader } from '../../components/loader';
import {
  useCartContext,
  useCustomerContext,
} from '../../context/app_context';
import { parseToNaira } from '../../utils/functions';
import {
  Collection,
  InputDetails,
} from './collection';
import {
  Delivery,
  DeliveryOption,
  DeliveryPaymentOption,
} from './delivery';

export default function ProductCheckout({ history }) {
    const { store, changeStore, cartTotal, makeOrder } = useCartContext();
    const { store: customer } = useCustomerContext();
    useEffect(() => {
        if (store.order_complete) {
            changeStore("items", []);
            changeStore("last_item", {});
            history.push("/order/confirmation");
        }
    }, [store.order_complete]);
    const setCheckOutType = (value) => {
        changeStore("checkout_type", value);
        changeStore("checkout_page", value);
    };
    const setStep = (value) => {
        changeStore("checkout_page", value);
    };
    const checkOutType = store.checkout_type;
    const checkOutPage = store.checkout_page;
    return (
        <Container bgClass="mb-20">
            {store.creating_order && <FullScreenLoader />}
            <div className="border-b-2 border-deep-red border-opacity-25 mt-10 py-4 flex justify-between">
                <div className="text-deep-red flex items-center font-semibold">
                    <img
                        className="inline-block h-5 mr-1"
                        src={require("./../../assets/shopping-basket.svg")}
                        alt=""
                    />
                    Basket Summary ({store.items?.length})
                </div>
                <span className="text-xl font-semibold text-k6-gray">
                    {parseToNaira(cartTotal)}
                </span>
            </div>
            <div className="text-deep-red flex items-center font-semibold py-4">
                <spanput
                    in
                    a
                    file
                    className="w-5 h-5 flex items-center justify-center text-2xl text-black font-normal ml-1 transform rotate-90"
                >
                    &rsaquo;
                </spanput>
                Step 1: delivery
            </div>
            <div className="bg-pink-200 px-4 py-8 md:p-8">
                <div className="flex justify-center space-x-5">
                    <button
                        onClick={() => setCheckOutType("collection")}
                        className={` ${
                            checkOutType === "collection" && "delivery"
                        } flex items-center px-5 md:px-12 py-3 bg-white border border-deep-red text-deep-red font-semibold whitespace-no-wrap text-xs md:text-lg`}
                    >
                        <img
                            src={require("./../../assets/truck (2).svg")}
                            className="  w-6 mr-3 md:mr-5"
                            alt=""
                        />
                        Collect in store
                    </button>
                    <button
                        onClick={() => setCheckOutType("delivery")}
                        className={` ${
                            checkOutType === "delivery" && "delivery"
                        } flex items-center px-5 md:px-12 py-3 bg-white border border-deep-red text-deep-red font-semibold text-xs whitespace-no-wrap md:text-lg`}
                    >
                        <img
                            src={require("./../../assets/click.svg")}
                            className="w-6 mr-3 md:mr-5"
                            alt=""
                        />
                        Deliver to me
                    </button>
                </div>
            </div>
            <div className="bg-white border-deep-red border">
                {checkOutPage === "collection" && <Collection />}
                {checkOutPage === "delivery" && <Delivery />}

                {checkOutPage === "delivery_option" && (
                    <div className="p-6">
                        <DeliveryOption />
                        <button
                            onClick={() => setStep("delivery")}
                            className="mt-16 text-deep-red px-6 py-2 mr-4 border border-deep-red rounded-md text-sm"
                        >
                            Change delivery address
                        </button>
                    </div>
                )}
                {checkOutPage === "payment" && (
                    <div className="p-6">
                        <DeliveryPaymentOption />
                        <div className="flex mt-16">
                            {checkOutType === "collection" ? (
                                <button
                                    onClick={() => setStep("collection")}
                                    className=" text-deep-red px-6 py-2 mr-4 border border-deep-red rounded-md text-sm"
                                >
                                    Change collection location
                                </button>
                            ) : (
                                <button
                                    onClick={() => setStep("delivery_option")}
                                    className=" text-deep-red px-6 py-2 mr-4 border border-deep-red rounded-md text-sm"
                                >
                                    Change delivery option
                                </button>
                            )}
                            {store.payment_gateway && (
                                <button
                                    onClick={() =>
                                        makeOrder(customer.customer_details?.id)
                                    }
                                    className="px-12 md: px-20 mx-auto py-2 rounded-md text-sm bg-deep-red text-white"
                                >
                                    Place order
                                </button>
                            )}
                        </div>
                    </div>
                )}
                {checkOutPage === "input_details" && (
                    <div className="p-6">
                        <InputDetails />
                    </div>
                )}
            </div>
        </Container>
    );
}
