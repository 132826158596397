import React from "react"

import { parseToNaira } from "../utils/functions"
import { Container } from "./container"
import MedicalInsight from "./medical_insight"
import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <>
      <MedicalInsight align="left" />
      <div className="border-t-2 border-deep-red">
        <Container>
          <div className="grid grid-cols-2 gap-4 md:gap-8 px-5 md:px-0 md:flex justify-around mt-12 mb-16">
            <FooterTop />
          </div>
          <div className="flex justify-center items-center">
            <span className="text-xs font-bold mr-2">Connect with us</span>
            {[
              {
                image: require("../assets/facebook (1).svg"),
                link: "https://web.facebook.com/mophethpharmacy"
              },
              {
                image: require("../assets/instagram (1).svg"),
                link: "https://www.instagram.com/mophethrx/"
              },
              {
                image: require("../assets/twitter (1).svg"),
                link: "https://twitter.com/MophethPharmacy"
              }
            ].map(v => (
              <a className="mx-2" href={v.link}>
                <img className="h-8 w-8" alt="" src={v.image} />
              </a>
            ))}
          </div>

          <div className="lg:px-16 md:flex flex-wrap justify-between items-start  mb-10 my-20">
            <div className="md:block flex flex-col items-center">
              <img
                src={require("../assets/mophet-logo.png")}
                alt=""
                className="h-40 mx-auto"
              />
              <p>Leading pharmacy brand in Nigeria</p>
              <a
                className="flex mb-3 mt-6"
                href="mail-to:onlinestore@mophethgroup.com"
              >
                <img
                  className="mr-2"
                  alt=""
                  src={require("../assets/mail-24px.svg")}
                />
                <span>onlinestore@mophethgroup.com</span>
              </a>
              <a className="flex mb-3" href="tel:08081585229">
                <img
                  className="mr-2"
                  alt=""
                  src={require("../assets/call-24px.svg")}
                />
                <span>08081585229</span>
              </a>
              <p className="flex">
                <img
                  className="mr-2"
                  alt={""}
                  src={require("../assets/location_on-24px.svg")}
                />
                <span>23A, Adeola Odeku Street, Victoria Island, Lagos.</span>
              </p>
            </div>
            <div className="grid grid-cols-2 md:flex justify-between mt-8">
              <FooterItem
                title="Customer Services"
                list={[
                  { name: "Help & FAQs", link: "/faq" },
                  { name: "Delivery information" },
                  { name: "Returns & exchange" },
                  { name: "Contact us", link: "/contact-us" }
                ]}
              />
              <FooterItem
                title="About Mopheth"
                list={[
                  { name: "Company information", link: "/about-us" },
                  { name: "Corporate social responsibility" },
                  { name: "Media ", link: "/media" },
                  { name: "Career", link: "/career" },
                  { name: "Privacy & cookies", link: "/privacy-cookies" },
                  { name: "Terms & conditions", link: "/terms-and-condition" }
                ]}
              />
              <FooterItem
                title="Payment Accepted"
                images={[
                  <img
                    alt=""
                    className="w-1/3 md:w-auto md:h-16"
                    src={require("./../assets/Interswitch.png")}
                  />,
                  <img
                    alt=""
                    className="w-1/3 md:w-auto md:h-8"
                    src={require("./../assets/Image 1.png")}
                  />,
                  <div className="w-1/3 md:w-full flex items-center">
                    <img
                      alt=""
                      className="h-6 md:h-8 mr-2"
                      src={require("./../assets/bank.svg")}
                    />
                    <span className="text-sm md:text-3xl ml-22 text-k3-blue font-extrabold ">
                      Pay by bank
                    </span>
                  </div>
                ]}
              />
            </div>
          </div>
          <div>
            <p className="text-center text-mid-gray mb-16 mt-24">
              Copyright &copy; {new Date().getFullYear()} Mopheth Pharmacy. All
              rights reserved.
            </p>
          </div>
        </Container>
      </div>
    </>
  )
}

function FooterItem({ title, list = [], images = [] }) {
  return (
    <div className={`lg:pr-16 text-black ${!!images.length && "col-span-2"}`}>
      <div className="mb-10">
        <h6
          className={`${
            !!images.length && "hidden md:block"
          } whitespace-no-wrap text-sm font-bold`}
        >
          {title}
        </h6>
      </div>

      {!!list.length && (
        <ul className="space-y-2">
          {list.map(l => (
            <li className="text-xs">
              <Link to={l.link}>{l.name}</Link>
            </li>
          ))}
        </ul>
      )}
      {!!images.length && (
        <ul className="space-x-5 md:space-x-0 md:space-y-5 w-full md:w-auto flex md:flex-col justify-center md:justify-start items-center md:items-start">
          {images.map(im => im)}
        </ul>
      )}
    </div>
  )
}

function FooterTop() {
  const data = [
    { image: require("../assets/help.svg"), text: "COVID-19 updates" },
    {
      image: require("../assets/delivery.svg"),
      text: "Free Delivery to Lekki and VI"
    },
    {
      image: require("../assets/XMLID_1460_.svg"),
      text: "Collect points everytime you shop with us"
    },
    { image: require("../assets/text.svg"), text: "Chat with us" }
  ]
  return (
    <>
      {data.map(d => (
        <div className="flex flex-col items-center">
          <img className="h-12" src={d.image} alt="" />
          <h6 className="mt-3 text-xs text-center md:text-left font-bold">
            {d.text}
          </h6>
        </div>
      ))}
    </>
  )
}
