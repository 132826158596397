import React from 'react';

export const Input = ({ label, onInput, value, type = "text", name }) => (
    <div>
        <label className="text-xs">
            {label}
            <span className="text-deep-red">*</span>{" "}
        </label>
        <input
            required
            value={value}
            onChange={(e) => onInput(name, e.currentTarget.value)}
            type={type}
            className="w-full mb-3 border rounded-md h-10 pl-3"
        />
    </div>
);
