import React from "react";
import {Container} from "../components/container";
import {Loader} from "../components/loader";
import {useLocalPickupLocation} from "../hooks/fetch_hooks";

export function FindStore() {
    const locations = useLocalPickupLocation();
    return (
        <Container bgClass="mt-10 mb-20">
            <div className="border-b-2 border-deep-red mt-10 py-4 flex justify-between">
                <div className="text-deep-red flex items-center font-semibold">
                    <img
                        className="inline-block h-5 mr-1"
                        src={require("./../assets/heartbeat (1).svg")}
                        alt=""
                    />
                    Find a store
                </div>
            </div>
            <p className="text-sm text-k6-gray my-10">
                We are available 24/7 in all our stores.
            </p>
            {locations.loading && <Loader/>}
            <div className="flex flex-wrap divide-x divide-deep-red">
                {
                    locations.data.filter(v => v.name.split('|')?.[0]?.trim() === 'PICKUP').map((st, i) => (
                        <p className="px-10 py-5 my-3 text-xs text-mid-gray">
                            <span className="font-bold">{st.name.split('|')?.[1]}</span>
                            <br/>
                            <span>{st.name.split('|')?.[2]}</span>
                            <br/>
                            <span>{st.name.split('|')?.[3]?.trim()}, {st.name.split('|')?.[4]}</span>
                            <br/>
                            <span>{st.open}</span>
                            {st.phone && <span> &nbsp;-&nbsp;{st.phone}</span>}
                        </p>
                    ))
                }

            </div>
        </Container>
    )
}
