import 'react-tippy/dist/tippy.css';

import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Tooltip } from 'react-tippy';

import {
  useCartContext,
  useCustomerContext,
} from '../context/app_context';
import CartPopUp from './cart_popup';
import { Container } from './container';

export function Header() {
    // const customer = useCustomerContext();

    const toggleMobileNav = () => {
        document.getElementById("navMobile").classList.toggle("invisible");
    };
    return (
        <>
            <Container bgClass="hidden md:block bg-light-gray">
                <div className="top-bar py-1 whitespace-no-wrap flex text-sm">
                    <span className="ml-auto">
                        <img
                            alt=""
                            className="inline-block pr-2"
                            src={require("./../assets/Icon_location.svg")}
                        />
                        We are available 24/7 in all our stores. Orders placed
                        after 12PM are delivered next day
                    </span>
                    <Link to={"/loyalty-scheme"} className="ml-auto">
                        Join Mopheth Loyalty Program
                    </Link>
                </div>
            </Container>
            <Container bgClass="bg-white shadow-md md:shadow-none relative z-50">
                <div className="flex items-center py-2 md:my-4">
                    <Link to="/">
                        <img
                            src={require("./../assets/mophet-logo.png")}
                            alt="mopheth"
                            className="h-16 md:h-32"
                        />
                    </Link>
                    <div className="flex-1 hidden md:block">
                        <SearchBar />
                    </div>
                    <button
                        onClick={toggleMobileNav}
                        className="md:hidden ml-auto space-y-1"
                    >
                        <span className="h-1 bg-deep-red rounded-sm w-6 block" />
                        <span className="h-1 bg-deep-red rounded-sm w-10 block" />
                        <span className="h-1 bg-deep-red rounded-sm w-8 block" />
                    </button>
                    {/*<SearchBar/>*/}
                    <div className="hidden md:flex divide-x-2 divide-black mx-5">
                        <Link to={"/find-store"} className="px-2 block">
                            Find a store
                        </Link>
                        <AvatarArea />
                    </div>
                    <span className="relative z-50 mt-2 md:mt-0 ml-5 md:ml-0 inline-block">
                        <ShowCartPopUp />
                    </span>
                </div>
            </Container>
            <div className="mt-3 mb-1 md:hidden">
                <SearchBar />
            </div>
        </>
    );
}
export const AvatarArea = () => {
    const customer = useCustomerContext();

    return customer.store.customer_details ? (
        <UserAvatar username={customer.store.customer_details?.username} />
    ) : (
        <Link to={"/login"} className="px-2 block">
            Login
        </Link>
    );
};
const UserAvatarDropDown = ({ logoutUser }) => {
    const logout = () => {
        logoutUser();
        document.getElementById("navMobile").classList.toggle("invisible");
        window.location = "/login";
    };
    return (
        <div className="flex flex-col divide-y-2">
            <a href={"/user"} className="px-5 py-2 cursor-pointer">
                Profile
            </a>
            <button onClick={logout} className="px-5 py-2 cursor-pointer">
                Logout
            </button>
        </div>
    );
};
const UserAvatar = ({ username }) => {
    const customer = useCustomerContext();
    return (
        <Tooltip
            trigger="click mouseenter"
            position="bottom"
            theme="light"
            interactive
            html={<UserAvatarDropDown logoutUser={customer.logoutUser} />}
        >
            <span className="cursor-pointer px-2 flex items-center capitalize">
                <img
                    src={require("./../assets/account_circle-black-18dp.svg")}
                    alt=""
                    className="ml-2 mr-1"
                />{" "}
                {username}
            </span>
        </Tooltip>
    );
};

const ShowCartPopUp = () => {
    const cart = useCartContext();
    const buttonRef = useRef();
    useEffect(() => {
        if (cart.store.pop_up && buttonRef.current) {
            buttonRef.current.click();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            cart.changeStore("pop_up", false);
            setTimeout(() => {
                if (document.getElementById("cart-pop-123")) {
                    buttonRef.current.click();
                }
            }, 5000);
        }
    }, [cart]);
    return (
        <CartPopUp>
            {({ onClick }) => (
                <button className="relative" ref={buttonRef} onClick={onClick}>
                    {!!cart.store.items.length && (
                        <span className="absolute pointer-events-none top-0 left-0 -m-1 bg-deep-red text-white font-bold h-4 w-4 rounded-full flex items-center justify-center text-xs">
                            {cart.store.items.length}
                        </span>
                    )}
                    <img
                        src={require("./../assets/Cart Button.svg")}
                        className="h-6 md:h-8"
                        alt="cart button"
                    />
                </button>
            )}
        </CartPopUp>
    );
};

const SearchBar = () => {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        const url = new URLSearchParams(location.search);
        const s = url.get("keyword");
        if (s) {
            setSearch(s);
        } else {
            setSearch("");
        }
    }, [location.search]);
    const [search, setSearch] = useState("");
    const handleSearch = (e) => {
        e.preventDefault();
        if (search) {
            history.push("/products?keyword=" + search);
        }
    };
    return (
        <form
            onSubmit={handleSearch}
            className="flex flex-1 px-2 mx-8 border border-mid-gray rounded-full items-center"
        >
            <input
                className="bg-transparent w-full outline-none flex-1 px-3"
                placeholder="Search by keyword"
                value={search}
                required
                onChange={(e) => setSearch(e.currentTarget.value)}
            />
            <button type="submit">
                <img
                    src={require("./../assets/search.svg")}
                    className="p-3 h-12"
                    alt="search"
                />
            </button>
        </form>
    );
};
