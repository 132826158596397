import React, {
    useMemo,
    useState,
} from 'react';

import {
    Handles,
    Rail,
    Slider,
    Tracks,
} from 'react-compound-slider';
import {Link} from 'react-router-dom';

import {
    Handle,
    Track,
} from './slider_components';
import {parseToNaira} from "../utils/functions";

const sliderStyle = {
    margin: "5%",
    position: "relative",
    width: "90%",
};

const railStyle = {
    position: "absolute",
    width: "100%",
    height: 6,
    borderRadius: 7,
    cursor: "pointer",
    backgroundColor: "rgb(155,155,155)",
};


function getRelatedCategories(products = []) {
    const map = [];
    return products
        .reduce((ac, item) => {
            return [...ac, ...item.categories];
        }, [])
        .filter((cat) => {
            if (!map.includes(cat.id)) {
                map.push(cat.id);
                return cat;
            }
            return false;
        });
}

function getBrands(products = []) {
    const map = [];
    return Array.from(new Set(products
        .map((item) => {
            const i = item.attributes?.find(
                (v) => v.name.toLowerCase() === "brand"
            );
            return i?.options?.join(", ");
            // return { id: i?.id, name: i?.options.join(", ") };
        })));

}

function SideFilter({products, setFilter,filter, range, setRange, domain, attribute, setAttribute}) {

    const relatedCategory = useMemo(() => getRelatedCategories(products), [
        products,
    ]);
    const brands = useMemo(() => getBrands(products), [products]);
    // console.log(brands)
    return (
        <>
            <div className="shadow-md w-full bg-white divide-y ">
                <FilterItem title="Related Categories">
                    <ul className="space-y-2 divide-y divide-gray-200">
                        {relatedCategory.slice(0, 10).map((rc) => (
                            <li
                                key={rc.id}
                                className="hover:text-blue-600 hover:underline"
                            >
                                <Link to={"/products?category=" + rc.id}>
                                    {rc.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </FilterItem>
                <FilterItem title="Filter by">
                    <div className="h-10 border rounded-full flex items-center pl-2 pr-2">
                        <input
                            value={filter}
                            onChange={(e) => setFilter(e.currentTarget.value)}
                            className="flex-1 min-w-0 px-1"
                        />
                        <button
                            onClick={() => setFilter("")}
                            className="text-2xl"
                        >
                            ✕
                        </button>
                    </div>
                </FilterItem>
                <FilterItem title="Price">
                    <div style={{minHeight: "40px"}}>
                        <Slider
                            mode={1}
                            step={1}
                            domain={domain}
                            rootStyle={sliderStyle}
                            onChange={setRange}
                            values={range}
                        >
                            <Rail>
                                {({getRailProps}) => (
                                    <div
                                        style={railStyle}
                                        {...getRailProps()}
                                    />
                                )}
                            </Rail>
                            <Handles>
                                {({handles, getHandleProps}) => (
                                    <div className="slider-handles">
                                        {handles.map((handle) => (
                                            <Handle
                                                key={handle.id}
                                                handle={handle}
                                                domain={domain}
                                                getHandleProps={getHandleProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Handles>
                            <Tracks left={false} right={false}>
                                {({tracks, getTrackProps}) => (
                                    <div className="slider-tracks">
                                        {tracks.map(
                                            ({id, source, target}) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={
                                                        getTrackProps
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </Tracks>
                        </Slider>
                        <div className="text-sm pl-0 pt-8">
                            <strong>{parseToNaira(range[0])}</strong>
                            <span> - </span>
                            <strong>{parseToNaira(range[1])}</strong>
                        </div>
                    </div>
                </FilterItem>
                <FilterItem title="Brand">
                    <ul className="space-y-2">
                        {brands.map((b) => (
                            <li className="cursor-pointer text-sm">
                               <label className="flex items-center">
                                   <input checked={b === attribute} onChange={e => setAttribute(e.currentTarget.value)} className="inline-block mr-2" type="radio" name="brand" value={b}/>
                                   <span>{b}</span>
                               </label>
                            </li>
                        ))}
                    </ul>
                </FilterItem>

            </div>
            <div className="mt-8 mb-24">
                <h6 className="p-2 font-bold flex items-center">
                    Product Tags
                </h6>
                <div className="flex flex-wrap">
                    {relatedCategory.slice(0, 10).map((rc) => (
                        <Link
                            key={rc.id}
                            to={"/products?category=" + rc.id}
                            className="border hover:border-red-600 hover:text-red-600 rounded-full text-xs inline-block px-3 py-1 mb-2 mr-1"
                        >
                            {rc.name}
                        </Link>
                    ))}
                </div>
            </div>
        </>
    );
}

export default SideFilter;

function FilterItem({title, children, open = false}) {
    const [opened, setOpened] = useState(open);

    return (
        <div className="">
            <h6
                onClick={() => setOpened(!opened)}
                className={`${
                    opened && "border-b"
                } p-2 font-semibold cursor-pointer flex items-center`}
            >
                <span
                    className={`${
                        opened ? "rotate-90" : ""
                    } transform transition-transform duration-300 origin-center inline-block mx-2 text-2xl`}
                >
                    &#9656;
                </span>
                {title}
            </h6>
            <div
                className={`${
                    opened ? "ml-4 p-3" : "h-0"
                } transition-all duration-300 overflow-hidden text-k-gray`}
            >
                {children}
            </div>
        </div>
    );
}
