import React from 'react';

export const Container = ({ bgClass, centered = true, children}) => {
    return (
        <div className={bgClass}>
            <div className={`containr ${centered ? "mx-auto px-4 md:px-6 lg:px-10 " : ""}`}>
                {children}
            </div>
        </div>
    );
};
