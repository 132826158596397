import React, { useCallback } from 'react';

import {
  fetchCosmeticSection,
  useAirtable,
} from '../utils/airtable';

export function CosmeticSection() {
    const func = useCallback(
        () => fetchCosmeticSection().then((data) => data.map((d) => d.fields)),
        []
    );
    const data = useAirtable(func)?.[0] || {};
    console.log({ data });
    return (
        <div className="hidden md:block my-16 relative">
            <img src={data?.Attachments?.[0]?.url} className="w-full" alt="" />
            <div className="absolute top-0 bottom-0 h-full md:w-1/2  flex flex-col justify-center items-start ml-20">
                <h6 className="font-black text-5xl text-white">
                    <pre className="font-black">{data?.title}</pre>
                </h6>
                <p className="text-k-yellow font-semibold text-xl mt-2 mb-5">
                    {data?.subtitle}
                </p>
                <a
                    href={data?.url}
                    className="py-2 px-5 text-deep-gray text-xs font-bold bg-white"
                >
                    Learn more
                </a>
            </div>
        </div>
    );
}
