export function getProductBrand(product) {
    return product.attributes
        ?.find((v) => v.name.toLowerCase() === "brand")
        ?.options.join(", ");
}

export const parseToNaira = (value) => {
    const v = value || 0;
    return (+v).toLocaleString("en-NG", { style: "currency", currency: "NGN" });
};
