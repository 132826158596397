import './styles/styles.scss';
import './styles/tailwind.css';

import React, {useEffect} from 'react';

import {
    BrowserRouter as Router,
    Route,
    Switch,
    useHistory,
    useLocation,
} from 'react-router-dom';

import Footer from './components/footer';
import {Header} from './components/header';
import {Navbar} from './components/navbar';
import {AppProvider} from './context/app_context';
import {AllPost} from './pages/blog';
import {BlogPost} from './pages/blog-post';
import ProductCheckout from './pages/checkout/product-checkout';
import LandingPage from './pages/landing';
import Login from './pages/login';
import ProductCart from './pages/product-cart';
import ProductDetails from './pages/product-details';
import ProductsPage from './pages/products';
import Register from './pages/register';
import {Order} from "./pages/order";
import {Services} from "./pages/services";
import {LoyaltyScheme} from "./pages/loyalty-scheme";
import {FindStore} from "./pages/find-store";
import {Prescription} from "./components/prescription";
import {FAQ} from "./pages/faq";
import {TermsAndCondition} from "./pages/termsandcondition";
import {AboutUs} from "./pages/about";
import {Career} from "./pages/career";
import {PrivacyCookies} from "./pages/privacy-cookies";
import {Media} from "./pages/media";
import {ContactUs} from "./pages/contact-us";
import {UserArea} from "./pages/user-area";
import {OrderDetail} from "./pages/order_detail";
import ProductDealsPage from "./pages/product-deals-page";
import {CategoryPost} from "./pages/blog-category";

function ScrollToTop() {
    const {pathname} = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (history.action === "PUSH") {
            window.scrollTo(0, 0);
        }
    }, [pathname, history.action]);

    return null;
}

function App() {
    return (
        <div className="App mx-auto" style={{overflowX: 'hidden', maxWidth: "2000px"}}>
            <AppProvider>
                <Router>
                    <ScrollToTop/>
                    <Prescription/>
                    <Header/>
                    <Navbar/>
                    <Switch>
                        <Route
                            exact
                            path="/products"
                            component={ProductsPage}
                        />
                        <Route
                            exact path="/product-deals" component={ProductDealsPage}
                        />
                        <Route
                            exact
                            path="/product/details/:product_id"
                            component={ProductDetails}
                        />
                        <Route exact path={"/cart"} component={ProductCart}/>
                        <Route
                            exact
                            path={"/checkout"}
                            component={ProductCheckout}
                        />
                        <Route exact path={"/order/confirmation"} component={Order}/>
                        <Route exact path={"/order/:order_id"} component={OrderDetail}/>
                        <Route exact path={"/login"} component={Login}/>
                        <Route exact path={"/register"} component={Register}/>
                        <Route exact path={"/blog/category/:cat_slug"} component={CategoryPost}/>
                        <Route exact path="/blog/:id" component={BlogPost}/>
                        <Route exact path="/blog" component={AllPost}/>
                        <Route exact path="/services" component={Services}/>
                        <Route exact path="/loyalty-scheme" component={LoyaltyScheme}/>
                        <Route exact path={"/find-store"} component={FindStore}/>
                        <Route exact path={"/faq"} component={FAQ}/>
                        <Route exact path={"/about-us"} component={AboutUs}/>
                        <Route exact path={"/terms-and-condition"} component={TermsAndCondition}/>
                        <Route exact path={"/career"} component={Career}/>
                        <Route exact path={'/privacy-cookies'} component={PrivacyCookies}/>
                        <Route exact path={"/media"} component={Media}/>
                        <Route exact path={"/contact-us"} component={ContactUs}/>
                        <Route exact path={'/user'} component={UserArea}/>

                        <Route component={LandingPage}/>
                    </Switch>
                    <Footer/>
                </Router>
            </AppProvider>
        </div>
    );
}

export default App;
