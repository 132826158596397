import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { useCustomerContext } from '../context/app_context';
import { Container } from './container';
import { AvatarArea } from './header';

const categoryLink = (id) => `/products?category=${id}`;
const navigation = [
    {
        item: "Shop by department",
        child: [
            {
                name: "Otc medication",
                child: [
                    { name: "Cough, Cold & Flu", link: categoryLink(157) },
                    { name: "Pain Management", link: categoryLink(326) },
                    { name: "First Aid", link: categoryLink(58) },
                    { name: "Antacids", link: categoryLink(327) },
                    { name: "Eye Care", link: categoryLink(100) },
                    {
                        name: "Ear, Nose and Throat Care",
                        link: categoryLink(328),
                    },
                    { name: "Pill Organizers", link: categoryLink(329) },
                    { name: "Supports and Braces", link: categoryLink(330) },
                    { name: "Home test kits", link: categoryLink(291) },
                    { name: "Skin Ailments", link: categoryLink(311) },
                    { name: "Digestive Health", link: categoryLink(331) },
                    { name: "Stop smoking aids", link: categoryLink(332) },
                    { name: "Incontinence", link: categoryLink(138) },
                    { name: "Children Health", link: categoryLink(333) },
                ],
            },
            { name: "Vitamins & Supplement", link: categoryLink(65) },
            {
                name: "Personal Care",
                child: [
                    { name: "Oral Care", link: categoryLink(39) },
                    { name: "Foot Care", link: categoryLink(334) },
                    { name: "Lip Care", link: categoryLink(335) },
                    { name: "Eye Care", link: categoryLink(100) },
                    { name: "Feminine Care", link: categoryLink(11) },
                    { name: "Shaving & Grooming", link: categoryLink(151) },
                    {
                        name: "Deodorants & Body Sprays",
                        link: categoryLink(296),
                    },
                ],
            },
            {
                name: "Beauty",
                child: [
                    { name: "Hair care", link: categoryLink(46) },
                    { name: "Facial care", link: categoryLink(297) },
                    { name: "Skin care", link: categoryLink(54) },
                    { name: "Body wash", link: categoryLink(295) },
                    { name: "Body lotions", link: categoryLink(294) },
                    { name: "Body Oils", link: categoryLink(336) },
                    { name: "Hand washes", link: categoryLink(337) },
                    { name: "Bath soaps/Soap", link: categoryLink(338) },
                    { name: "Perfumes", link: categoryLink(120) },
                    { name: "Body sprays", link: categoryLink(296) },
                ],
            },
            { name: "Home health care", link: categoryLink(291) },
            { name: "Baby & Children care", link: categoryLink(48) },
            { name: "Grocery", link: categoryLink(126) },
            { name: "Households", link: categoryLink(323) },
            { name: "Mopheth Brands" },
        ],
    },
    {
        item: "Prescription",
        className: "presc-link",
        handle: () => {
            document
                .getElementById("prescription")
                .classList.remove("invisible");
        },
    },
    {
        item: "Services",
        child: [
            { name: "Health Checks", bold: true, link: "/services" },
            { name: "BP Checks", link: "/services#bp-check" },
            { name: "Blood Sugar", link: "/services#blood-sugar" },
            { name: "BMI check", link: "/services#bmi-check" },
            { name: "Cholesterol Check", link: "/services#cholesterol" },
            { name: "Health Clubs", bold: true, link: "/loyalty-scheme" },
        ],
        child2: (
            <>
                <strong>HEALTH CLUBS</strong>
                <br />
                Join an exciting club where you get emails and advice from our
                pharmacists about these
                <br />
                conditions:
                <br />
                Diabetes
                <br />
                Hypertension
                <br />
                Cancer
                <br /> Asthma <br /> Heart disease <br /> Breast feeding
                <br />
                Obesity
                <br />
                Skin Disorder
                <br />
                Mental Health
                <br />
                <strong>
                    <Link to={'/loyalty-scheme'}>JOIN NOW</Link>
                </strong>
            </>
        ),
    },
    {
        item: "Advice",
        link: "/blog/category/advice",
        child: [],
    },
    {
        item: "Offers",
        link: "/product-deals",
        child: [],
    },
];

export function Navbar() {
    const [show, setShow] = useState("");
    const [show2, setShow2] = useState("");
    const customer = useCustomerContext();
    const [showNotify, setShowNotify] = useState(true);
    const toggleMobileNav = () => {
        document.getElementById("navMobile").classList.toggle("invisible");
    };
    const item = navigation.find((v) => v.item === show);
    return (
        <div className="relative z-30">
            <div
                id="navMobile"
                className="invisible fixed bottom-0 left-0 right-0 top-0 h-full mt-20 bg-white w-full shadow py-5 pl-5 pr-8 flex flex-col z-50 md:z-0 md:hidden"
            >
                <ul className="flex-1 overflow-y-auto text-deep-gray space-y-3">
                    {!item &&
                        navigation.map((nav, i) => {
                            if (nav.className) {
                                return (
                                    <button
                                        key={nav.item}
                                        className={"w-full"}
                                        onClick={nav.handle}
                                    >
                                        <span className="flex text-2xl items-center justify-between ">
                                            {nav.item}
                                            <span className="text-3xl ">
                                                &rsaquo;
                                            </span>
                                        </span>
                                    </button>
                                );
                            } else if (nav.link) {
                                return (
                                    <li key={nav.item}>
                                        <Link
                                            onClick={toggleMobileNav}
                                            to={nav.link}
                                        >
                                            <span className="flex text-2xl items-center justify-between ">
                                                {nav.item}
                                                <span className="text-3xl ">
                                                    &rsaquo;
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                );
                            } else {
                                return (
                                    <li
                                        key={nav.item}
                                        onClick={() => setShow(nav.item)}
                                    >
                                        <span className="flex text-2xl items-center justify-between ">
                                            {nav.item}
                                            <span className="text-3xl ">
                                                &rsaquo;
                                            </span>
                                        </span>
                                    </li>
                                );
                            }
                        })}
                    {!!item && !show2 && (
                        <>
                            <button
                                onClick={() => setShow("")}
                                className="text-deep-red text-2xl"
                            >
                                <span className="flex  items-center">
                                    <span className="text-3xl mr-3 inline-block pb-1">
                                        &lsaquo;
                                    </span>
                                    {item.item}
                                </span>
                            </button>
                            {item.child &&
                                item.child.map((nav, i) => (
                                    <li className="text-xl">
                                        {nav.link ? (
                                            <Link
                                                to={nav.link}
                                                onClick={toggleMobileNav}
                                            >
                                                {nav.name}
                                            </Link>
                                        ) : (
                                            <button
                                                onClick={() => setShow2(nav)}
                                            >
                                                {nav.name}
                                            </button>
                                        )}
                                    </li>
                                ))}
                        </>
                    )}
                    {!!show2 && (
                        <>
                            <button
                                onClick={() => setShow2("")}
                                className="text-deep-red text-2xl"
                            >
                                <span className="flex  items-center">
                                    <span className="text-3xl mr-3 inline-block pb-1">
                                        &lsaquo;
                                    </span>
                                    {show2.name}
                                </span>
                            </button>
                            {show2.child &&
                                show2.child.map((nav, i) => (
                                    <li className="text-xl">
                                        <Link
                                            to={nav.link}
                                            onClick={toggleMobileNav}
                                        >
                                            {nav.name}
                                        </Link>
                                    </li>
                                ))}
                        </>
                    )}
                </ul>
                <div className="flex justify-center mt-auto pt-12 text-lg mb-20 divide-x-2 divide-black mx-5">
                    <Link
                        onClick={toggleMobileNav}
                        to={"/find-store"}
                        className="px-5 block"
                    >
                        Find a store
                    </Link>
                    <AvatarArea />
                </div>
            </div>
            <div className={` hidden md:block relative ${show && "z-30"}`}>
                <Container bgClass="bg-k9-gray">
                    <div className=" flex text-white space-x-8">
                        {navigation.map((nav, i) => (
                            <NavbarItem
                                key={i + "nv"}
                                activeItem={show}
                                setActive={setShow}
                                itemName={nav.item}
                                itemList={nav.child}
                                itemChild2={nav.child2}
                                itemClass={nav.className}
                                itemHandle={nav.handle}
                                itemLink={nav.link}
                            />
                        ))}
                    </div>
                </Container>
            </div>
            {showNotify && (
                <Container bgClass="bg-k-red mt-2 py-6">
                    <div className="flex items-center">
                        <img
                            src={require("../assets/deal.svg")}
                            alt={""}
                            className="h-10 mr-3"
                        />
                        <div className="flex-1 text-sm flex flex-col">
                            <h5 className="text-k-yellow md:text-white font-semibold">
                                Amazing Deals on select items this month
                            </h5>
                            <h6 className="block text-white font-light md:font-normal">
                                Enjoy great offers on some select items
                            </h6>
                        </div>
                        <button
                            onClick={() => setShowNotify(false)}
                            className="text-xl font-black"
                        >
                            ✕
                        </button>
                    </div>
                </Container>
            )}
        </div>
    );
}

function NavbarItem({
    itemName,
    itemList,
    itemChild2 = [],
    itemClass,
    itemLink,
    itemHandle,
    activeItem,
    setActive,
}) {
    const [sideChild1, setSC1] = useState(null);
    const navItem = () => {
        if (itemClass) {
            return (
                <div>
                    <button
                        onClick={itemHandle}
                        className={
                            itemClass +
                            " hover:font-bold cursor-pointer py-4 inline-block"
                        }
                    >
                        {itemName}
                    </button>
                </div>
            );
        } else if (itemLink) {
            return (
                <div>
                    <Link
                        to={itemLink}
                        className={
                            "hover:font-bold cursor-pointer py-4 inline-block"
                        }
                    >
                        {itemName}
                    </Link>
                </div>
            );
        } else {
            return (
                <div onMouseEnter={() => setActive(itemName)}>
                    <span
                        className={`cursor-pointer py-4 inline-block ${
                            itemName === activeItem && "font-bold"
                        }`}
                    >
                        {itemName}
                    </span>
                </div>
            );
        }
    };

    return (
        <div onMouseLeave={() => setActive("")}>
            {navItem()}
            {itemName === activeItem && !!itemList && !!itemList.length && (
                <div
                    style={{ margin: 0, top: "100%", color: "#1B1B1B" }}
                    className="absolute flex items-start left-0 p-6 shadow-lg bg-white w-screen"
                >
                    <div className="flex flex-col pr-6 border-r border-deep-red">
                        {itemList.map((v) => (
                            <a
                                href={v.link ? v.link : "#!"}
                                onClick={() => {
                                    setSC1(v.child);
                                    if (v.link) {
                                        setActive("");
                                    }
                                }}
                                onMouseEnter={() => setSC1(v.child)}
                                className={`block flex-1 p-3 ${
                                    v.child && v.child.length
                                        ? "cursor-pointer"
                                        : ""
                                } ${v.bold ? "font-bold" : ""}`}
                            >
                                {v.name}
                            </a>
                        ))}
                    </div>

                    {sideChild1 && sideChild1.length && (
                        <div className="flex flex-col pl-10 pr-6 border-r border-deep-red">
                            {sideChild1.map((v) => (
                                <Link
                                    to={v.link}
                                    className={`block text-sm flex-1 p-3 ${
                                        v.bold ? "font-bold" : ""
                                    }`}
                                >
                                    {v.name}
                                </Link>
                            ))}
                        </div>
                    )}
                    {itemChild2 && <div className={"pl-10"}>{itemChild2}</div>}
                </div>
            )}
        </div>
    );
}
