import {Container} from "./container";
import React from "react";

export function GenLayout({title, children, padding = true}) {
    return (
        <Container bgClass="mt-10 mb-20">
            <div className={`border-b-2 border-deep-red mt-10 mb-10 py-4 flex justify-between`}>
                <div className="text-deep-red flex items-center font-semibold">
                    <img
                        className="inline-block h-5 mr-1"
                        src={require("./../assets/heartbeat (1).svg")}
                        alt=""
                    />
                    {title}
                </div>
            </div>
            <div className={`${padding ? 'px-10' : ''}`}>
                {children}
            </div>
        </Container>
    )
}
