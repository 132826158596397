import React from 'react'
import {Container} from "./container";
import {useOnSaleProducts} from "../hooks/fetch_hooks";
import {Loader} from "./loader";
import {Link} from "react-router-dom";

export function ProductDeals(){
    const products = useOnSaleProducts();
    return (
        <Container bgClass="hidden md:block">
            <div className="mb-16 mt-32 mx-20">

                <img
                    src={require("./../assets/Image 7.png")}
                    className="w-full"
                    alt=""
                />
            </div>
            <h2 className="text-center text-deep-gray section__title font-semibold">
                Check out these great deals on select products
            </h2>
            {products.loading && <div className="mt-20"> <Loader/></div>}

            <div className="mt-24 grid grid-cols-5 justify-between gap-8">
                {
                products.data.map((prod) => (
                    <Product border={false} product={prod}/>
                ))}
            </div>
            <div className="text-center">
                <Link to={'/product-deals'} className="font-semibold text-k-red">View more deals</Link>
            </div>
        </Container>
    )
}

function Product({product}){
    return (
        <Link
            to={`/product/details/${product.id}`}
            className={`pt-8 px-4 pb-4 rounded-lg mx-auto flex flex-col justify-center`}
        >
            <img
                className={"h-48 object-contain"}
                src={product.images?.[0]?.src}
                alt={product.name}
            />
            <div className="mt-auto py-4">
                <h6 className="font-semibold text-sm text-center">{product.name}</h6>
                <p className="text-xs mb-2 mt-3 font-bold text-center">
                    <span
                        dangerouslySetInnerHTML={{ __html: product.price_html }}
                    />
                </p>
                <button className="text-xs text-black opacity-50 mt-1 mx-auto block">
                    SHOP NOW
                </button>
            </div>
        </Link>
    )
}
