import React, {useCallback, useEffect, useState} from 'react';

import {Container} from '../components/container';
import {ImageSlider} from '../components/image_slider';
import {CategoriesSlider} from '../components/products_slider';
import {ProductDeals} from "../components/product_deals";
import {TrendingProducts} from "../components/trending_products";
import {FeaturedProducts} from "../components/featured_products";
import {fetchBannerSliders, useAirtable} from "../utils/airtable";
import {Link} from "react-router-dom";
import {CosmeticSection} from "../components/cosmetic_section";

export default function LandingPage() {
    const func = useCallback(() => fetchBannerSliders()
        .then(
            (data) => data.map(d => d.fields)
        ), [])
    const images = useAirtable(func)
    console.log(images)
    return (
        <div>
            <div className="my-2 overflow-x-hidden">
                <div className="w-screen z-0">
                    <ImageSlider
                        animationClass="image-slide"
                        images={images}
                    />
                </div>
                <div className="md:hidden flex flex-col items-center px-3 my-10">
                    <h3 className="text-2xl mx-10 leading-tight font-semibold text-center text-k-gray">
                        Shop online & have them delivered same day.
                    </h3>
                    <p className=" text-deep-gray text-base  my-4">
                        Shop the things you love easily
                    </p>
                    <button
                        className="inline-block border-2 text-sm rounded px-10 py-2 font-semibold border-k8-gray text-k2-gray">
                        Shop now
                    </button>
                </div>
            </div>
            <Container bgClass="hidden md:block bg-k9-gray">
                <div className="flex py-6 justify-around divide-x-4 divide-white">
                    {[
                        {
                            title: "Same day delivery",
                            icon: require("./../assets/truck.svg"),
                            onClick: () => {
                            },
                        },
                        {
                            title: "Free pick up in-store",
                            icon: require("./../assets/mobile-store.svg"),
                            onClick: () => {
                            },
                        },
                        {
                            title: "Pharmacy Chat",
                            icon: require("./../assets/chat.svg"),
                            onClick: () => window.Tawk_API.toggle(),
                        },
                    ].map((v) => (
                        <div
                            role="button"
                            onClick={v.onClick}
                            className=" cursor-pointer flex flex-1 px-10 justify-between items-center text-white"
                        >
                            <h6 className="mr-6 text-lg">{v.title}</h6>
                            <img
                                src={v.icon}
                                className="h-16 ml-auto"
                                alt={v.title}
                            />
                        </div>
                    ))}
                </div>
            </Container>
            <Container bgClass="md:my-12">
                <div
                    className="flex-col md:flex-row flex mx-6 md:mx-12 justify-center space-y-5 md:space-y-0 md:space-x-5">
                    <Link to={"/products?category=59"}
                          className="w-full md:w-1/2"
                          ><img
                        alt="drug container"
                        className="w-full"
                        src={require("./../assets/weightloss.png")}
                    /></Link>
                    <Link  className="w-full md:w-1/2" to={"/products?category=65"}>
                    <img
                        alt="drug container"
                        className="w-full"
                            src={require("./../assets/immuneboost.png")}
                    />
                    </Link>
                </div>
                <div className={"flex justify-center md:space-x-24 mt-24 mb-6 text-deep-gray"}>
                    {[{
                        image: require('../assets/shop (1).svg'),
                        title: 'Free Order & Collect',
                        subtitle: 'Pick up your order from our store free'
                    },
                        {
                            image: require('../assets/delivery.svg'),
                            title: 'Next day delivery',
                            subtitle: 'Available on orders placed after 12pm'
                        }].map((it, i) => <div className="flex space-x-3">
                        <img className="h-12 row-span-2" src={it.image} alt={""}/>
                        <div>
                            <h5 className="font-bold">{it.title}</h5>
                            <p className="text-xs text-gray-900">{it.subtitle}</p>
                        </div>
                    </div>)}
                </div>
            </Container>
            <Container>
                <h5 className="text-center text-deep-gray text-xl md:text-5xl font-semibold md:font-bold mt-24 mb-16">
                    Shop from a wide range of products
                </h5>
                <CategoriesSlider/>
            </Container>
            <Container>
                <div className="red-shadowed bg-deep-red my-10 py-6 px-3 text-white text-center">
                    <h5 className="text-xl md:text-3xl font-black ">
                        Save on bestselling products
                    </h5>
                    <p className="font-normal md:text-lg mt-5">
                        SALE Save up to 1/2 price on products you love
                    </p>
                </div>
            </Container>
            <TrendingProducts/>
            <CosmeticSection/>
            <FeaturedProducts/>
            <ProductDeals/>
            <div className="hidden md:grid grid-cols-2 text-deep-gray  items-stretch my-32">
                <div>
                    <img className="h-full w-full object-cover" alt={""}
                         src={require('../assets/Mopheth-Water-copy.jpg')}/>
                </div>
                {/*<div className="relative">*/}
                {/*    <div className="ab-center w-full max-w-xl bg-opacity-75 bg-white p-10 ">*/}
                {/*        <h6 className="text-xl font-semibold">Shop the Mopheth way</h6>*/}
                {/*        <p className="text-xs">Shop conveniently anytime online. Experience shopping the Mopheth*/}
                {/*            way.</p>*/}
                {/*    </div>*/}
                {/*    <img*/}
                {/*        alt=""*/}
                {/*        className="w-full object-cover h-full"*/}
                {/*        src={require("./../assets/grayscale-of-black-family-exercising-PNH92AG.png")}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="relative">
                    <div className="ab-center w-full max-w-xl bg-opacity-75 bg-white p-10 ">
                        <h6 className="text-xl font-semibold">Family</h6>
                        <p className="text-xs">Learn about healthy tips for your family</p>
                    </div>
                    <img
                        alt=""
                        className="w-full object-cover h-full"
                        src={require("./../assets/black-family-enjoy-precious-time-together-happines.png")}
                    />
                </div>
            </div>
        </div>
    );
}
