import {GenLayout} from "../components/gen-layout";
import React, {useState} from "react";
import {parseToNaira} from "../utils/functions";

const DeliveryPrices = () => {
    const data = [
        {location: "Victoria island and Lekki", price: "Free"},
        {location: "Agungi, Ikoyi, Festac, Lagos Island", price: parseToNaira(500)},
        {location: "Ajah to Sangotedo, Surulere, Yaba, Gbagada, Oworo", price: parseToNaira(1000)},
        {location: "Ketu, Ikeja", price: parseToNaira(1500)},
        {location: "Iyanapaja, Ikorodu", price: parseToNaira(2000)}
    ]
    return (
        <table className="table-auto text-left border">
            <thead>
            <tr>
                <th className="border px-4 py-2">Locations (and their environments)</th>
                <th className="border px-4 py-2">Delivery fees</th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(
                    d => (
                        <tr>
                            <td className="border px-4 py-2">{d.location}</td>
                            <td className="border px-4 py-2">{d.price}</td>
                        </tr>
                    )
                )
            }
            </tbody>
        </table>
    )
}

const faqs = [
    {
        question: "How do I get a refund or replacement?",
        answer: "Unfortunately, we aren't able to refund or exchange items as we have a strict no returns policy."
    },
    {
        question: "How long will my delivery take?",
        answer: "If your order is placed before 12 noon, it will be delivered on the same day. Orders placed after 12 noon will not be delivered same day. Delivery time for orders placed after 12 noon is between 24 hours to 36 hours.",
    },
    {
        question: "What are the delivery prices?",
        answer: <DeliveryPrices/>,
    },
    {
        question: "What do I do if I was debited twice?",
        answer: "If you are debited twice, Kindly visit your bank. ",
    },
    {
        question: "How can I contact Mopheth’s Customer Care?",
        answer: <p>To contact our Customer Care, send an email to <a
            href="mailto:onlinestore@mophethgroup.com">onlinestore@mophethgroup.com</a> OR call us <a
            href="tel:08081585229">08081585229</a></p>,
    },
    {
        question: "How do I speak to a pharmacist?",
        answer: <>
            Kindly click on this
            <button
                className="text-blue-500 font-semibold underline"
                onClick={() => document.getElementById('prescription').classList.remove('invisible')}
            >link</button>
        </>,
    },
    {
        question: "How do I apply for a job at Mopheth?",
        answer: <>
            If you are an excellent, passionate person who embraces innovation and fears God,
            Mopheth Group is an organization you would like to join, send CV to
            <a href="mailto:cv@mophethgroup.com">cv@mophethgroup.com</a>
        </>,
    },
]

export function FAQ() {
    const [opened, setOpened] = useState(null)
    return (
        <GenLayout title={'Frequently Asked Questions'}>
            <div className="divide-y-2 accordion transition-transform">
                {
                    faqs.map((faq, i) => (
                        <div className="accordion__item py-4">
                            <div onClick={() => opened === i ? setOpened(null) : setOpened(i)} className={`${opened === i ? 'text-deep-red font-bold opened': 'text-gray-800'} accordion__question cursor-pointer`}>{faq.question}</div>
                            <div className={`${opened === i ? 'opened' : ''} overflow-hidden accordion__answer`}>{faq.answer}</div>
                        </div>
                    ))
                }
            </div>
        </GenLayout>
    )
}
