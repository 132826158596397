import React from 'react';

import {Container} from './container';
import {Link} from "react-router-dom";

export default function MedicalInsight({align}) {
    return (
        <Container>
            <div className={"mb-8 md:mb-20"}>
                <h2
                    className={`text-${align} hidden md:block text-deep-gray section__title font-semibold mb-16`}
                >
                    Health Corner
                </h2>
                <div className="md:w-3/4 overflow-y-hidden mx-auto mb-10 md:mb-0 grid md:grid-cols-2 md:grid-rows-3 row-gap-5
                 col-gap-5">
                    <img
                        alt=""
                        className=" hidden md:block w-full h-full object-cover row-span-3"
                        src={require("../assets/mosante-magazine.jpg")}
                    />
                    <div className="relative">
                      <Link to={'/blog/category/health-tips'} className="absolute bottom-0 left-0 w-84 m-4 bg-opacity-75 bg-white p-6 text-deep-gray ">
                        <h6 className="text-xl font-semibold">Health Tips</h6>
                        <p className="text-xs">Learn important tips for personal hygiene</p>
                    </Link>
                        <img
                            alt=""
                            className="w-full object-cover h-full"
                            src={require("./../assets/healthtips.png")}
                        />
                    </div>
                    <div className="relative">
                      <Link to={"/blog"} className="absolute bottom-0 left-0 w-84 m-4 bg-opacity-75 bg-white p-6 text-deep-gray ">
                        <h6 className="text-xl font-semibold">Blog</h6>
                        <p className="text-xs">Read articles and advice from Mopheth</p>
                    </Link>
                        <img
                            alt=""
                            className="w-full object-cover h-full"
                            src={require("./../assets/blogtips.png")}
                        />
                    </div>
                    <div className="relative">
                      <Link to={'/blog/category/videos'} className="absolute bottom-0 left-0 w-84 m-4 bg-opacity-75 bg-white p-6 text-deep-gray ">
                        <h6 className="text-xl font-semibold">Videos</h6>
                        <p className="text-xs">Watch videos from Mopheth</p>
                    </Link>
                        <img
                            alt=""
                            className="w-full object-cover h-full"
                            src={require("./../assets/mvideo.png")}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row md:w-9/12 mx-auto justify-center mb-8 md:mb-20">
                <a className="w-full md:w-1/2 flex-1 " href="https://www.webmd.com/interaction-checker/default.htm" target="_blank" rel="noreferrer noopener">
                    <img className="w-full h-auto object-contain" alt={""} src={require('../assets/drug interaction.jpg')}/>
                </a>
                <Link to={'/loyalty-scheme'} className="flex-1 md:w-1/2">
                    <img className="" alt="" src={require('../assets/mopheth loyalty card.jpg')}/>
                </Link>
            </div>
        </Container>
    );
}
