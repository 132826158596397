import {GenLayout} from "../components/gen-layout";
import React from "react";

export function Career() {
    return (
        <GenLayout title={'Join our team'}>
            <div className={"text-deep-gray"}>
                <p>
                    If you are excellent, passionate person who embraces innovation and fears God, MOPHETH is an
                    organization you will like to join.
                </p>
<br/>
                <strong> Send your CV to cv@mophethgroup.com</strong>
            </div>
        </GenLayout>
    )
}
