import React from 'react';

import { Container } from './container';
import { Loader } from './loader';
import {useCategories, useFeaturedProducts} from "../hooks/fetch_hooks";
import {Category, Product} from "./products_slider";

export function FeaturedProducts() {
    const products = useFeaturedProducts();
    const category = useCategories(154);

    return (
        <Container bgClass="hidden md:block">
            <div className="flex justify-center items-center space-x-6">
                <span className="block h-3 w-16 mt-2 bg-deep-red rounded-full" />
                <h6 className="text-deep-gray text-2xl">
                    Order before 12pm and have it delivered same day
                </h6>
                <img
                    alt=""
                    className=""
                    src={require("./../assets/truck (1).svg")}
                />
            </div>
             {category.loading && (
                <div className="mt-20">
                    {" "}
                    <Loader />
                </div>
            )}

            <div className="mt-24 flex justify-between space-x-6">
                {category.data.slice(0, 4).map((cat) => (
                    <div className="sm:w-1/2 md:w-4/12 lg:w-3/12">
                        <Category category={cat} />
                    </div>
                ))}
            </div>

        </Container>
    );
}
