import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import { Link } from 'react-router-dom';

import { useCartContext } from '../context/app_context';

export function PopUp({ bg, type = "", body, children }) {
    const [popup, setPopUp] = useState(false);
    const clickRef = useRef();
    useOutsideAlerter(clickRef, () => setPopUp(false));

    return (
        <>
            {popup && bg && (
                <div className="h-screen w-screen bg-black bg-opacity-25 fixed z-50 top-0 left-0" />
            )}
            <div ref={clickRef} className={`relative ${popup && "z-50"}`}>
                {children({
                    onClick: () => setPopUp(!popup),
                })}
                {popup && (
                    <>
                        <div
                            className={`${
                                type === "center"
                                    ? "absolute-center"
                                    : "absolute right-0  mt-4"
                            } ${
                                bg || "popup"
                            } z-30  shadow-lg border border-deep-red bg-white p-5 w-84`}
                        >
                            <div className="popup-wrapper">
                                {body({ popup, setPopUp })}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

function CartPopUpBody({ popup, setPopUp }) {
    const cart = useCartContext();
    const last_item = cart.store.last_item;
    const show = cart.itemInCart(last_item.id);

    console.log(last_item);
    return (
        <>
            {show && last_item.id && (
                <>
                    <div id="cart-pop-123" className="flex items-center">
                        <img
                            alt=""
                            src={last_item?.images?.[0]?.src}
                            className="w-1/2 border mr-3"
                        />
                        <div>
                            <h6 className="font-bold mt-4">
                                {last_item?.name}
                            </h6>
                            <p className="text-xs text-k4-gray mt-3">
                                Quantity: {show?.quantity}
                            </p>
                            <p className="text-right text-lg mt-5 font-extrabold">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: last_item?.price_html,
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                    <div className=" rounded-sm flex items-center mt-5">
                        <button
                            onClick={() =>
                                cart.incrementDecrementItem(
                                    last_item.id,
                                    "d",
                                    last_item.stock_quantity
                                )
                            }
                            className="px-2 text-white font-bold text-xl bg-deep-red border-r"
                        >
                            -
                        </button>
                        <span className="px-3 text-center flex-1 text-k5-gray">
                            {show.quantity}
                            <br />
                            <span className="text-xs">added to your cart</span>
                        </span>
                        <button
                            onClick={() =>
                                cart.incrementDecrementItem(
                                    last_item.id,
                                    "i",
                                    last_item.stock_quantity
                                )
                            }
                            className="px-2 text-white font-bold bg-deep-red text-xl border-l"
                        >
                            +
                        </button>
                    </div>
                </>
            )}
            <div
                className={`text-center flex flex-col ${
                    last_item.id ? "mt-6" : ""
                }`}
            >
                {show && last_item.id && (
                    <>
                        {" "}
                        <p className="text-lg mt-5 font-extrabold">
                            Subtotal: ₦{last_item.price * show.quantity}
                        </p>
                        <p className="text-xs text-k4-gray mt-3">
                            ({show?.quantity} product
                            {show?.quantity !== 1 && "s"})
                        </p>
                    </>
                )}
                <Link
                    to={"/cart"}
                    onClick={() => setPopUp(false)}
                    className="py-3 text-xs  bg-deep-red text-white font-semibold px-5 mt-3 rounded-md"
                >
                    View basket & checkout
                </Link>
                <button
                    onClick={() => setPopUp(false)}
                    className="py-3 text-xs  text-k4-gray"
                >
                    Continue shopping
                </button>
            </div>
        </>
    );
}

export default function CartPopUp({ children }) {
    return <PopUp body={(b) => <CartPopUpBody {...b} />} children={children} />;
}
// function GoToCartBody({ popup, setPopUp }) {
//     return (
//         <div className="text-center flex flex-col">
//             <Link
//                 to={"/cart"}
//                 onClick={() => setPopUp(false)}
//                 className="py-3 text-xs  bg-deep-red text-white font-semibold px-5 mt-3 rounded-md"
//             >
//                 View basket & checkout
//             </Link>
//             <button
//                 onClick={() => setPopUp(false)}
//                 className="py-3 text-xs  text-k4-gray"
//             >
//                 Continue shopping
//             </button>
//         </div>
//     );
// }
// // export function GotoCart({ children }) {
// //     return <PopUp body={(b) => <GoToCartBody {...b} />} children={children} />;
// // }

export function useOutsideAlerter(ref, handler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
