import axios from 'axios';

import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

export const API_URL = "https://store.mophethpharmacy.com"; //?rest_route=/wp/v2";
const consumer_key = "ck_9b3aba94e3e626272eafa2ddadeb3dbe1ba9927f";
const consumer_secret = "cs_a4d2eed0f526ef639ad16d0a0d0751931e5f2d6d";

const api = new WooCommerceRestApi({
    url: API_URL,
    consumerKey: consumer_key,
    consumerSecret: consumer_secret,
    version: "wc/v3",
});
export function fetchCategories(parent) {
    const url = `products/categories${parent ? "?parent=" + parent : ""}`;
    return api.get(url, { per_page: 100 });
}

export function getCategoryDetail(id) {
    return api.get("products/categories/" + id);
}
export function getProducts(data) {
    return api.get("products", {
        ...JSON.parse(data),
        status: "publish",
        stock_status: "instock",
    });
}
export function getSpecificProductsById(ids = []) {
    console.log(ids);
    return api.get("products", {
        include: JSON.parse(ids),
        status: "publish",
        stock_status: "instock",
    });
}
export function getProductDetails(id) {
    return api.get("products/" + id);
}

export function createCustomer(data) {
    return api.post("customers", data);
}
export function getCustomerDetails(id) {
    return api.get("customers/" + id);
}
export function login(data) {
    return axios.post(API_URL + "/wp-json/aam/v2/authenticate", data);
}

export function getOnSaleProducts() {
    return api.get("products", { on_sale: true, per_page: 10 });
}
export function featuredProducts() {
    return api.get("products", {
        featured: true,
        status: "publish",
        stock_status: "instock",
    });
}

export function getLocalPickupLocation() {
    return api.get("shipping/zones");
}

export function getShippingMethods() {
    return api.get("shipping_methods");
}
export function getOtherZoneShippingMethod() {
    return api.get("shipping/zones/0/methods");
}
export function getPaymentGateways() {
    return api.get("payment_gateways");
}

export function getPaymentGatewayDetails(id) {
    return api.get("payment_gateways/" + id);
}

export function createOrder(data) {
    return api.post("orders", data);
}
export function updateOrder(id, data) {
    return api.put("orders/" + id, data);
}

export function getShippingLineDetails(id) {
    return api.get("shipping/zones/0/methods/" + id);
}

export function getCustomerOrders(id) {
    return api.get("orders", {
        customer: id,
    });
}
