import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Container } from '../components/container';
import { usePost } from '../utils/blog';

export const AllPost = () => (
    <>
        <Hero />
        <Insights />
    </>
);

export const Hero = ({title = 'Blog'}) => {
    return (
        <div id="hero" className="relative mt-2 nice-hero bg-right bg-cover">
            <h1 className="py-24 text-5xl font-semibold text-white text-center">
                {title}
            </h1>
            <p className="absolute text-pred bottom-0 mb-5 text-center w-full font-medium">
                {new Date().toString().slice(0, 15)}
            </p>
        </div>
    );
};

const Insights = () => {
    const [offset, setOffset] = useState(0);
    const posts = usePost({ type: "latest", limit: 12, offset });

    const decrementCurrent = () => setOffset(offset === 0 ? 0 : offset - 1);
    const incrementCurrent = () => setOffset(offset + 1);
    return (
        <Container>
            <div className="my-20">
                <div className="flex flex-wrap">
                    <Articles blogPosts={posts} />
                </div>

                <div className="flex justify-center mt-8">
                    <button
                        onClick={decrementCurrent}
                        className="border-2 border-gray-200 test-pred  px-2 h-12"
                    >
                        <span className="arrow-style">&larr;</span>
                    </button>
                    <span className="border px-4 flex items-center font-semibold">
                        {offset + 1}
                    </span>
                    <button
                        onClick={incrementCurrent}
                        className="bg-pred text-white px-2 h-12"
                    >
                        <span className="arrow-style">&rarr;</span>
                    </button>
                </div>
            </div>
        </Container>
    );
};

export const Articles = ({ blogPosts }) => {
    return (
        <>
            {!blogPosts.loading && !blogPosts.posts.length && (
                <div className="text-center py-16 w-full">No posts found</div>
            )}
            {blogPosts.posts.map((article, i) => (
                <div
                    key={"artic" + i}
                    className="flex-shrink-0 w-full h-full md:w-1/2 px-3 pb-5 lg:w-1/3"
                >
                    <div
                        className={`blog-card-shadow hover:shadow-sm
                 bg-white text-black`}
                    >
                        <img
                            src={blogPosts.image(article)}
                            className="bg-gray-200 w-full h-40 object-cover"
                            alt=""
                        />
                        <div className="p-5">
                            <span className="inline-block truncate whitespace-no-wrap text-xs bg-pred text-white px-4 py-1">
                                {article._embedded["wp:term"][0]
                                    .slice(0, 2)
                                    .map((v) => v.name)
                                    .join(", ")}
                            </span>
                            <h4 className="mt-3 mb-5 truncate">
                                {article.title.rendered}
                            </h4>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: article.excerpt.rendered,
                                }}
                                className="text-k-gray text-xs h-8 overflow-hidden"
                            />
                            <Link
                                to={`/blog/${article.id}`}
                                className="mt-10 flex items-baseline py-2 uppercase text-xs font-semibold text-k2-gray"
                            >
                                Know More{" "}
                                <span className="text-pred arrow-style text-sm">
                                    &rarr;
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
