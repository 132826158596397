import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Link } from 'react-router-dom';

import { Loader } from '../../components/loader';
import {
  useCartContext,
  useCustomerContext,
} from '../../context/app_context';
import { useLocalPickupLocation } from '../../hooks/fetch_hooks';

export const Collection = () => {
    const locations = useLocalPickupLocation();
    const { store: customer } = useCustomerContext();
    const { store, changeStore } = useCartContext();
    const handleLocation = (location) => {
        changeStore("collection_location", location);
    };
    const handleNext = () => {
        if (customer.customer_details) {
            //handle setting the billing and shipping details here
            const billing = {
                first_name: customer.customer_details?.first_name,
                last_name: customer.customer_details?.last_name,
                email: customer.customer_details?.email,
                required: true,
            };
            changeStore("billing_details", billing);
            changeStore("checkout_page", "payment");
        } else {
            changeStore("checkout_page", "input_details");
        }
    };
    return (
        <>
            <h6 className="text-2xl px-10 py-5 text-k7-gray">
                Select pick-up store
            </h6>
            {locations.loading && <Loader />}
            <div className="grid justify-start sm:justify-center sm:grid-cols-2 md:grid-cols-3 gap-5 py-5 px-10">
                {locations.data.map((st, i) =>
                    st.name.split("|")?.[0]?.trim() === "PICKUP" ? (
                        <label
                            key={st.name}
                            className="flex items-center space-x-6 md:space-x-2"
                        >
                            <input
                                type="radio"
                                checked={
                                    st.id === store.collection_location?.id
                                }
                                value={i}
                                onChange={() => handleLocation(st)}
                            />
                            <p className="text-xs text-mid-gray">
                                <span className="font-bold">
                                    {st.name.split("|")?.[1]}
                                </span>
                                <br />
                                <span>{st.name.split("|")?.[2]}</span>
                                <br />
                                <span>
                                    {st.name.split("|")?.[3]?.trim()},{" "}
                                    {st.name.split("|")?.[4]}
                                </span>
                                <br />
                                <span>{st.open}</span>
                                {st.phone && (
                                    <span> &nbsp;-&nbsp;{st.phone}</span>
                                )}
                            </p>
                        </label>
                    ) : (
                        <></>
                    )
                )}
            </div>
            <div className="mt-12 mb-6 flex justify-center space-x-5">
                <Link
                    to="/cart"
                    className="py-2 text-deep-red px-12 mr-4 border border-deep-red rounded-md text-sm"
                >
                    Go back
                </Link>
                {store.collection_location?.id && (
                    <button
                        onClick={handleNext}
                        className="bg-deep-red rounded-md font-semibold text-white px-8 py-2 text-sm"
                    >
                        Continue
                    </button>
                )}
            </div>
        </>
    );
};

export const InputDetails = () => {
    const [billing, setBilling] = useState({});
    const { store, changeStore } = useCartContext();
    const { store: customer } = useCustomerContext();
    const inputs = useMemo(
        () => [
            {
                label: "First Name",
                short: "first_name",
                default: customer.customer_details?.first_name,
                required: true,
            },
            {
                label: "Last Name",
                short: "last_name",
                default: customer.customer_details?.last_name,
                required: true,
            },
            {
                label: "Email",
                short: "email",
                default: customer.customer_details?.email,
                required: true,
                type: "email",
            },
            { label: "Phone No.", short: "phone", type: "number" },
        ],
        [customer]
    );
    useEffect(() => {
        const i = {};
        inputs.forEach((bi) => {
            i[bi.short] =
                store.billing_details[bi.short] ||
                billing[bi.short] ||
                bi.default ||
                "";
        });
        setBilling(i);
    }, [store, inputs]);
    const nextStep = (e) => {
        e.preventDefault();
        changeStore("billing_details", billing);
        changeStore("checkout_page", "payment");
    };
    return (
        <form onSubmit={nextStep}>
            <div className="p-5 bg-white shadow-md  lg:w-6/12 mx-auto m-8">
                <h6 className="font-semibold text-xl mb-5 text-k4-gray">
                    Billing Details
                </h6>
                {inputs.map((i) => (
                    <div className="flex items-center mb-3">
                        <label className="w-3/12 text-sm text-k4-gray">
                            {i.label}
                        </label>
                        {billing[i.short] !== undefined && (
                            <input
                                value={billing[i.short]}
                                onChange={(e) =>
                                    setBilling({
                                        ...billing,
                                        [i.short]: e.currentTarget.value,
                                    })
                                }
                                required={i.required}
                                type={i.type || "text"}
                                className="w-9/12 px-3 border h-10"
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="mt-12 mb-6 flex justify-center space-x-5">
                <button
                    type={"button"}
                    onClick={() => changeStore("checkout_page", "collection")}
                    className="py-2 text-deep-red px-12 mr-4 border border-deep-red rounded-md text-sm"
                >
                    Go back
                </button>

                <button
                    type={"submit"}
                    className="bg-deep-red rounded-md font-semibold text-white px-8 py-2 text-sm"
                >
                    Continue
                </button>
            </div>
        </form>
    );
};
