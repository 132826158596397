import React, { useState } from 'react';

import {
  Link,
  useHistory,
} from 'react-router-dom';

import { ErrorBadge } from '../components/auth_popup';
import { Container } from '../components/container';
import { Loader } from '../components/loader';
import { useCustomerContext } from '../context/app_context';
import { useApiCall } from '../hooks/fetch_hooks';
import { createCustomer } from '../utils/api';

export default function Register() {
    const callApi = useApiCall();
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        username: "",
    });
    const customerContext = useCustomerContext();
    const history = useHistory();
    const handleInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const submit = (e) => {
        e.preventDefault();
        callApi.callApi(
            () => createCustomer(formData),
            (data) => {
                console.log(data);
                customerContext.setUser({ ID: data.id });
                history.push("/");
            },
            (error) => {
                console.log(error);
                setError(error.data.message);
            }
        );
    };
    return (
        <Container>
            <div className="max-w-lg mx-auto md:shadow-md px-5 py-8 mt-20 mb-5">
                <form onSubmit={submit}>
                    <h6 className="text-center text-xl text-k4-gray font-black mb-4">
                        Create your account here
                    </h6>
                    <ErrorBadge error={error} close={() => setError("")} />
                    <InputField
                        label="First Name"
                        value={formData.first_name}
                        name="first_name"
                        onInput={handleInput}
                        placeholder="John"
                    />

                    <InputField
                        label="Last Name"
                        value={formData.last_name}
                        name="last_name"
                        onInput={handleInput}
                        placeholder="Doe"
                    />

                    <InputField
                        label="Email Address"
                        type="email"
                        value={formData.email}
                        name="email"
                        onInput={handleInput}
                        placeholder="example@example.com"
                    />

                    <InputField
                        label="Username"
                        value={formData.username}
                        name="username"
                        onInput={handleInput}
                        placeholder="john-doe"
                    />

                    <InputField
                        label="Password"
                        type="password"
                        value={formData.password}
                        name="password"
                        onInput={handleInput}
                        placeholder="Choose a strong password"
                    />
                    {callApi.loading ? (
                        <Loader />
                    ) : (
                        <>
                            <button
                                type="submit"
                                className="py-4 w-full text-base bg-deep-red text-center text-white font-bold px-5 mt-3 rounded-sm"
                            >
                                Register
                            </button>
                        </>
                    )}
                    <p className="text-xs text-gray-500 mt-3">
                        By clicking next and creating an account, you agree to
                        receive all communications related to your Mopheth
                        Account electronically in accordance with the Disclosure
                        and Consent to Electronic Delivery Agreement. Further,
                        you agree to Mopheth’s Terms of Use and Privacy Policy.
                    </p>
                </form>
            </div>
            <p className="text-center mb-12">
                Are you an existing customer?{" "}
                <Link
                    to="/login"
                    className="text-blue-500 pl-4 hover:underline"
                >
                    Login
                </Link>
            </p>
        </Container>
    );
}

const InputField = ({
    label,
    onInput,
    value,
    type = "text",
    name,
    placeholder,
}) => (
    <div className="border-b-2 mb-5">
        <label className="text-sm text-gray-600 mb-1">
            {label}
            <span className="text-deep-red">*</span>{" "}
        </label>
        <input
            required
            value={value}
            onChange={(e) => onInput(name, e.currentTarget.value)}
            type={type}
            placeholder={placeholder}
            className="w-full h-8 py-3 placeholder-gray-600"
        />
    </div>
);
