import {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import { API_URL as BASE_URL } from './api';

const API_URL = `${BASE_URL}?rest_route=/wp/v2`;
const categories = [
    {
        id: 308,
        name: "Health Tips",
        slug: "health-tips",
    },
    {
        id: 309,
        name: "Videos",
        slug: "videos",
    },
    {
        id: 2836,
        name: "Advice",
        slug: "advice",
    },
];
export const blogCategories = categories;

function fetchLatestPost(limit = 6, offset = 0) {
    return axios.get(
        `${API_URL}/posts&_embed&per_page=${limit}&orderby=date&offset=${
            offset * limit
        }`
    );
}

function fetchLatestPostByCategory(limit = 6, offset = 0, category) {
    return axios.get(
        `${API_URL}/posts&_embed&per_page=${limit}&orderby=date&${
            category ? "categories=" + category : ""
        }&offset=${offset * limit}`
    );
}

export function retrieveAPost(id) {
    return axios.get(`${API_URL}/posts/${id}&_embed`);
}

function postImage(post) {
    if (post.id) {
        return post._embedded["wp:featuredmedia"]
            ? post._embedded["wp:featuredmedia"]["0"].source_url
            : "";
    }
    return "";
}

export function usePost({
    type = "latest",
    categorySlug,
    limit,
    id,
    offset = 0,
}) {
    const [posts, setPosts] = useState([]);
    const [singlePost, setSinglePost] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    useEffect(() => {
        async function fetchPost() {
            setError("");
            setLoading(true);
            try {
                let res;
                if (type === "latest") {
                    res = await fetchLatestPost(limit, offset);
                }
                if (type === "latest-category") {
                    const cat = categories.find((c) => c.slug === categorySlug);
                    res = await fetchLatestPostByCategory(
                        limit,
                        offset,
                        cat && cat.id
                    );
                    console.log({ res });
                }
                if (type === "single") {
                    res = await retrieveAPost(id);
                }
                if (res && res.data && type !== "single") {
                    setPosts(res.data);
                } else if (res && res.data && type === "single") {
                    setSinglePost(res.data);
                }
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            } finally {
                setLoading(false);
            }
        }

        fetchPost();
    }, [type, categorySlug, id, limit, offset]);

    return { posts, loading, error, image: postImage, single: singlePost };
}
