import {Container} from "../components/container";
import React, {useState} from "react";
import {useApiCall} from "../hooks/fetch_hooks";
import axios from 'axios';
import {Loader} from "../components/loader";
export function LoyaltyScheme() {
    const callApi = useApiCall();
    const [success, showSuccess] = useState(false)
    const [state, setState] = useState({
        title: '',
        name: '',
        address: '',
        phone: '',
        email: '',
        dob: '',
        gender: '',
        occupation: '',
        join_health_club: true,
        subscribe: true
    });
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value
        })
    }
    const submit = (e) => {
        e.preventDefault()
        showSuccess(false)
        callApi.callApi(
            () => axios.post('https://mophethpharmacy.com/loyalty.php', {
                ...state,
                join_health_club: state.join_health_club ? 'Yes': 'No',
                subscribe: state.subscribe ? 'Yes' : 'No'
            }),
            () => {
                showSuccess(true)
                setState({
                    title: '',
                    name: '',
                    address: '',
                    phone: '',
                    email: '',
                    dob: '',
                    gender: '',
                    occupation: '',
                    join_health_club: true,
                    subscribe: true
                })
            }
        )
    }
    return (
        <Container bgClass="mt-10 mb-20">
            <div className="border-b-2 border-deep-red mt-10 py-4 flex justify-between">
                <div className="text-deep-red flex items-center font-semibold">
                    <img
                        className="inline-block h-5 mr-1"
                        src={require("./../assets/heartbeat (1).svg")}
                        alt=""
                    />
                    Mopheth Loyalty Scheme
                </div>
            </div>
            <p className="text-sm text-k6-gray my-10">
                Get points when you shop online or in any of our stores.
            </p>
            <div>
                <button
                    className={`delivery px-6 py-3 border border-deep-red text-deep-red text-xs`}
                >Register for a loyalty card
                </button>
            </div>
            <form onSubmit={submit} className="border border-deep-red mt-8 p-10 ">
                <div className="w-9/12 mx-auto">
                    <div className="l-form">
                        <label>
                            Title
                        </label>
                        <input name="title" value={state.title} required onChange={handleChange}/>
                    </div>
                    <div className="l-form">
                        <label>
                            Name
                        </label>
                        <input name="name" value={state.name} required onChange={handleChange}/>
                    </div>
                    <div className="l-form">
                        <label>
                            Address
                        </label>
                        <input name="address" value={state.address} required onChange={handleChange}/>
                    </div>
                    <div className="l-form">
                        <label>
                            Phone
                        </label>
                        <input name="phone" value={state.phone} required type="number" onChange={handleChange}/>
                    </div>
                    <div className="l-form">
                        <label>
                            Email
                        </label>
                        <input name="email" value={state.email} required type={'email'} onChange={handleChange}/>
                    </div>
                    <div>
                        <div className="l-form">
                            <label>
                                DOB
                            </label>
                            <input name="dob" value={state.dob} type={'date'} onChange={handleChange}/>
                        </div>
                        <div className="l-form">
                            <label>
                                Gender
                            </label>
                            <input name="gender" value={state.gender} onChange={handleChange}/>
                        </div>
                    </div>
                    <div className="l-form">
                        <label>
                            Occupation
                        </label>
                        <input name={'occupation'} value={state.occupation} onChange={handleChange}/>
                    </div>
                    <div className={"flex mb-5"}>
                        <div className="w-1/2">
                            <label className="pr-4 l-form-label">
                                Join Health Club
                            </label>
                            <input type="checkbox" name={'join_health_club'} checked={state.join_health_club} onChange={handleChange}/>
                        </div>
                        <div className="w-1/2">
                            <label className="pr-4 l-form-label">
                                Subscribe to get a copy of Mosante
                            </label>
                            <input type="checkbox" name="subscribe" checked={state.subscribe} onChange={handleChange}/>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    {callApi.loading ? <Loader/> : <button className="bg-pred text-white px-12 py-2 rounded">Submit</button>}
                </div>
                <div>
                    {success && <p className="text-green-500 font-bold text-center mt-3">Loyalty scheme registration successful</p>}
                </div>
            </form>
        </Container>
    )
}
